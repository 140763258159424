import { ErrorMessage } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
type props = {
  name: string
}

const FormErrorMessageBox: FC<React.PropsWithChildren<props>> = ({ name }) => {
  const { t } = useTranslation()
  return (
    <div className="block">
      {/* @ts-ignore */}
      <ErrorMessage
        name={name}
        render={(msg) => (
          <label className=" label">
            <span className=" label-text-alt text-error">{t(msg)}</span>
          </label>
        )}
      ></ErrorMessage>
    </div>
  )
}

export default FormErrorMessageBox
