import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type props = {
  message: string
  hasError: boolean
}

const ErrorMessageBox: FC<React.PropsWithChildren<props>> = ({
  message,
  hasError,
}) => {
  const { t } = useTranslation()
  return hasError && <div className="text-error text-sm p-1">{t(message)}</div>
}

export default ErrorMessageBox
