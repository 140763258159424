import { Button, useUI } from '@components/ui'
import analytics from '@lib/analytics'
import cn from 'classnames'
import { AuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownMenu from './DropdownMenu'
import s from './UserNav.module.css'
interface Props {
  className?: string
  currentUser: AuthUser
}

const UserNav: FC<React.PropsWithChildren<Props>> = ({
  className,
  currentUser,
}) => {
  const router = useRouter()
  const locale = router.locale
  const { openModal, setModalView } = useUI()
  const { t } = useTranslation()
  return (
    <nav className={cn(s.root, className)}>
      <div className={s.mainContainer}>
        <ul className={s.list}>
          {/* {currentUser.clientInitialized && currentUser?.id && (
            <li className={s.item}>
              <NotificationBox currentUser={currentUser} locale={locale} />
            </li>
          )} */}
          {currentUser.clientInitialized && currentUser?.id && (
            <li className={s.item}>
              <DropdownMenu currentUser={currentUser} />
            </li>
          )}

          {currentUser.clientInitialized && !currentUser?.id && (
            <>
              <li className={s.item}>
                <Button
                  type="button"
                  className="py-1 text-sm"
                  variant="slim"
                  aria-label="Menu"
                  onClick={() => {
                    setModalView('LOGIN_VIEW')
                    openModal()
                    analytics.track('Login button clicked', {
                      location: 'header',
                    })
                  }}
                >
                  {t('log')}
                </Button>
              </li>
              <li className={cn(s.item, 'hidden lg:block')}>
                <Button
                  type="button"
                  className="py-1 text-sm"
                  variant="outline"
                  aria-label="Menu"
                  onClick={() => {
                    setModalView('SIGNUP_VIEW')
                    openModal()
                    analytics.track('singup button clicked', {
                      location: 'header',
                    })
                  }}
                >
                  {t('sign')}
                </Button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  )
}
export default UserNav
