import { CloseEye, OpenEye } from '@components/icons'
import cn from 'classnames'
import React, { InputHTMLAttributes, useState } from 'react'
import s from './InputPassword.module.css'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onChangeHandler?: Function
}

const InputPassword: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, children, onChangeHandler, autoComplete, ...rest } = props

  const rootClassName = cn(s.root, {}, className)

  const [passwordShown, setPasswordShown] = useState(false)

  const togglePassword = (e) => {
    e.preventDefault()
    setPasswordShown(passwordShown ? false : true)
  }

  const handlePaste = (e) => {
    e.preventDefault()
  }

  const handleOnChange = (e: any) => {
    if (onChangeHandler) {
      onChangeHandler(e.target.value)
    }
    return null
  }
  return (
    <div className="relative">
      <label className="w-full">
        <input
          type={passwordShown ? 'text' : 'password'}
          className={rootClassName}
          onChange={handleOnChange}
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onPaste={handlePaste}
          {...rest}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <button onClick={togglePassword} type="reset">
            <i>{passwordShown ? <OpenEye /> : <CloseEye />}</i>
          </button>
        </div>
      </label>
    </div>
  )
}

export default InputPassword
