import { Link } from '@components/ui'
import TreeComponent from '@components/ui/tree'
import { LinkItem, Tree } from '@lib/types'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import s from './SideBar.module.css'

interface Props {
  id: string
  menus?: LinkItem[]
  isTree?: boolean
  treeMenus?: Tree
  activeTab?: string
  onAdd?: Function
  onEdit?: Function
  onDelete?: Function
  onSelect?: Function
  onActiveTab?: Function
  isAdmin?: boolean
  industryType?: number
  isCustom?: boolean
  customComponent?: JSX.Element
  nodeLimit?: number
}

const SideBar: FC<React.PropsWithChildren<Props>> = ({
  id,
  menus,
  isTree,
  treeMenus,
  isCustom = false,
  ...props
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <div
      className={' drawer-side min-h-[100vh] '}
      style={{ maxHeight: 'none' }}
    >
      <label htmlFor={id} className=" drawer-overlay"></label>
      <ul className=" menu w-80  overflow-y-auto  bg-base-100 text-base-content shadow-lg  border-r border-gray-500">
        {isTree ? (
          <TreeComponent
            data={treeMenus}
            onAdd={props.onAdd}
            onDelete={props.onDelete}
            activeTab={props.activeTab}
            onActiveTab={props.onActiveTab}
            onSelect={props.onSelect}
            child={1}
            isAdmin={props.isAdmin}
            industryType={props.industryType}
            totalDepthCount={props.nodeLimit}
            totalWidthCount={props.nodeLimit}
          />
        ) : isCustom ? (
          <> {props.customComponent}</>
        ) : (
          menus.map((menu, index) => (
            <li key={index}>
              <Link href={menu.url}>
                <a
                  className={cn(s.link, {
                    [s.active]:
                      router.asPath === menu.url ||
                      router.asPath === menu.home ||
                      router.asPath.split('?')[0] === menu.url,
                  })}
                >
                  {t(menu.name)}
                </a>
              </Link>
            </li>
          ))
        )}
      </ul>
    </div>
  )
}

export default SideBar
