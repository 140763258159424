import config from '@config/seo.json'
import { DefaultSeo } from 'next-seo'
import NextHead from 'next/head'
import { FC } from 'react'

const Head: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="application-name" content="Vurilo" />
        <meta name="apple-mobile-web-app-title" content="Vurilo" />
        <meta name="theme-color" content="#3ED1B0" />
        <meta
          name="title"
          content="Best social, fun, community-focused learning online platform in Nepal"
        />
        <meta
          name="description"
          content="Join the best hot and happening online learning platform in Nepal."
        />
        <meta
          name="keywords"
          content="online learning sites in nepal, free online courses in nepal, e learning app in nepal"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>
        <meta
          name="theme-color"
          // @ts-ignore
          media="(prefers-color-scheme: light)"
          content="#d2fbd0"
        />
        <meta
          name="theme-color"
          // @ts-ignore
          media="(prefers-color-scheme: dark)"
          content="#3ED1B0"
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3ED1B0" />
        <meta name="msapplication-TileColor" content="#d2fbd0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="196x196"
          href="/favicon/favicon_196.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/favicon/favicon_512.png"
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="#3ed1b0"
        ></meta>
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
      </NextHead>
    </>
  )
}

export default Head
