import { Config } from '@config/constants'
import { QuestionSetPostReturn, QuestionSetType } from '@lib/types'
import Cookies from 'js-cookie'
import { AuthUser } from 'next-firebase-auth'
import qs from 'query-string'

const teamId = Cookies.get('team-id') ?? ''

export const submitMcqQuestionSet = async (
  data: QuestionSetType,
  locale: string,
  currentUser: AuthUser
): Promise<QuestionSetPostReturn> => {
  const token = await currentUser.getIdToken()

  const res = await fetch(`${Config.API_BASE_URL}/api/questionset`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  }).then(async (r) => {
    if (!r.ok) {
      throw await r.json()
    }
    return await r.json()
  })
  return res
}

export const editMcqQuestionSet = async (
  data: QuestionSetType,
  locale: string,
  currentUser: AuthUser,
  identity: string
): Promise<QuestionSetPostReturn> => {
  const token = await currentUser.getIdToken()
  const res = await fetch(
    `${Config.API_BASE_URL}/api/questionset/${identity}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!res.ok) {
    throw await res.json()
  }

  return await res.json()
}

export const getQuestionSetDetail = (
  locale: string,
  currentUser: AuthUser,
  slug: string
): Promise<QuestionSetPostReturn> => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/questionset/${slug}`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}
//start
export const getQuestionSetLeaderBoard = (
  locale: string,
  currentUser: AuthUser,
  identity: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/questionset/${identity}/leaderboard`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}
//enmd
export const getAllQuestionSetList = (
  locale: string,
  currentUser: AuthUser,
  page?: number,
  search?: string,
  sortValue?: string,
  Scope?: string,
  EnrollmentStatus?: string,
  IsDeleted?: boolean,
  FreeOnly?: string,
  Status?: string,
  includePast?: boolean
) => {
  return currentUser.getIdToken().then((token) => {
    const sort = sortValue.split(':')
    const sortBy = sort[0]
    const sortType = sort[1]
    const queryString = qs.stringify(
      {
        ...(page && { page }),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
        ...(sortType && { sortType }),
        ...(includePast && { includePast }),
        ...(Scope && { Scope }),
        ...(EnrollmentStatus && { EnrollmentStatus }),
        ...(IsDeleted && { IsDeleted }),
        ...(FreeOnly && { FreeOnly }),
        ...(Status && { Status }),
      },
      { encode: false }
    )

    let url = `${Config.API_BASE_URL}/api/questionset?${queryString}`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        'x-team-id': teamId,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const deleteQuestionSet = (
  locale: string,
  currentUser: AuthUser,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/questionset/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const getQuestions = (
  locale: string,
  currentUser: AuthUser,
  poolIdentity: string
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify(
      {
        ...(poolIdentity && { poolIdentity }),
      },
      { encode: false }
    )

    return fetch(`${Config.API_BASE_URL}/api/question?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export type QuestionsFilterType = {
  type?: number
  tags?: string[]
  size?: number
  search?: string
  sortBy?: string
  sortType?: number
  page?: number
  questionsTags?: string
}

export const getQuestionsList = (
  locale: string,
  currentUser: AuthUser,
  poolIdentity: string,
  filters: QuestionsFilterType
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify(
      {
        ...(poolIdentity && { poolIdentity }),
        ...(filters.search && { search: filters.search }),
        ...(filters.tags && { tags: filters.tags }),
        ...(filters.size && { size: filters.size }),
        ...(filters.type && { type: filters.type }),
        ...(filters.sortBy && { sortBy: filters.sortBy }),
        ...(filters.sortType && { sortType: filters.sortType }),
        ...(filters.page && { page: filters.page }),
        ...(filters.questionsTags && { tags: filters.questionsTags }),
      },
      { encode: false }
    )

    return fetch(`${Config.API_BASE_URL}/api/question?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const addQuestionsToQuestionSet = (
  locale: string,
  currentUser: AuthUser,
  identity: string,
  questionPoolQuestionIds: any
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/questionset/${identity}/addquestion`,
      {
        method: 'POST',
        body: JSON.stringify(questionPoolQuestionIds),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return r
    })
  })
}

export const enrollQuestionSet = (
  currentUser: AuthUser,
  locale: string,
  questionSetId: string,
  amount: number,
  token: string,
  paymentGateWay: 'esewa' | 'khalti'
) => {
  return currentUser.getIdToken().then((idToken) => {
    return fetch(`${Config.API_BASE_URL}/api/questionset/enroll`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + idToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({
        token,
        amount,
        questionSetId,
        paymentGateWay: paymentGateWay === 'esewa' ? 6 : 2,
      }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const enrollQuestionSetFree = (
  currentUser: AuthUser,
  locale: string,
  questionSetId: string
) => {
  return currentUser.getIdToken().then((idToken) => {
    return fetch(`${Config.API_BASE_URL}/api/questionset/enroll`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + idToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({ questionSetId }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const addQuestionSetInvigilator = (
  currentUser: AuthUser,
  locale: string,
  setIdentity: string,
  emailOrPhoneNumber: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Questionsetteacher`

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        setIdentity,
        emailOrPhoneNumber,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r
    })
  })
}

export const deleteQuestionSetInvigilator = (
  currentUser: AuthUser,
  locale: string,
  setIdentity: string,
  userId: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/questionsetteacher/${setIdentity}/remove/${userId}`

    return fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r
    })
  })
}

export const updateQuestionSetRole = (
  currentUser: AuthUser,
  locale: string,
  setIdentity: string,
  userId: string,
  role: number
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/questionsetteacher/${setIdentity}/assignrole/${userId}`

    return fetch(url, {
      method: 'PATCH',
      body: JSON.stringify({
        role,
      }),

      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    })
  })
}

export const getQuestionSetInvigilator = (
  currentUser: AuthUser,
  locale: string,
  SetIdentity: string,
  search?: string,
  page?: number
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(search && { search }),
      ...(page && page > 0 && { page }),
      ...(SetIdentity && { SetIdentity }),
    })
    let url = `${Config.API_BASE_URL}/api/questionSetTeacher?${queryString}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const getQuestionSetQuestionList = (
  locale: string,
  currentUser: AuthUser,
  questionSetId: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/questionSet/${questionSetId}/questions`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getQuestionSetParticipants = (
  locale: string,
  currentUser: AuthUser,
  questionSetId: string,
  query: any
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(query.search && { search: query.search }),
      ...(query.page && query.page > 0 && { page: query.page }),
    })

    return fetch(
      `${Config.API_BASE_URL}/api/questionSet/${questionSetId}/participants?${queryString}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getQuestionSetStatistics = (
  currentUser: AuthUser,
  locale: string,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionSet/${id}/statistics`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const updateQuestionSetStatus = (
  currentUser: AuthUser,
  locale: string,
  questionSetIdentity: string,
  status: any
) => {
  return currentUser.getIdToken().then((token) =>
    fetch(
      `${Config.API_BASE_URL}/api/questionset/${questionSetIdentity}/changestatus/`,
      {
        method: 'PATCH',
        body: JSON.stringify(status),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r
    })
  )
}

export const updateQuestionSetQuestionOrder = (
  locale: string,
  currenUser: AuthUser,
  questionSetId: string,
  questionSetQuestionId: string[]
) => {
  currenUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/questionset/${questionSetId}/questions/order`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify({ questionSetQuestionId }),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const reportQuestion = async (
  currentUser: AuthUser,
  locale: string,
  questionId: string,
  message: string,
  identity: string
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(
    `${Config.API_BASE_URL}/api/questionset/${identity}/reportQuestion`,
    {
      method: 'PUT',
      body: JSON.stringify({
        questionId,
        message,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!res.ok) {
    throw await res.json()
  }

  return await res
}
