import { Tooltip } from '@components/common'
import {
  DotsHorizontal,
  IndustryAcademic,
  IndustryBanking,
  IndustryGovernment,
} from '@components/icons'
import ToggleIcon from '@components/icons/ToggleIcon'
import { Tree } from '@lib/types'
import AuthProvider from '@lib/utils/authHelper/authContext'
import toaster from '@lib/utils/notify'
import { useAuthUser } from 'next-firebase-auth'
import React, { FC, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onAdd: Function
  onDelete: Function
  onSelect: Function
  activeTab: string
  data: Tree
  child?: number
  onActiveTab: Function
  isAdmin: boolean
  industryType: number
  totalDepthCount: number
  totalWidthCount: number
}
const TreeComponent: FC<React.PropsWithChildren<Props>> = ({
  activeTab,
  data,
  onAdd,
  onDelete,
  onSelect,
  child = 1,
  onActiveTab,
  isAdmin,
  industryType,
  totalDepthCount,
  totalWidthCount,
}) => {
  const [childVisible, setChildVisiblity] = useState(true)

  return (
    <>
      <TreeNode
        child={++child}
        node={data}
        onAdd={onAdd}
        onDelete={onDelete}
        isAdmin={isAdmin}
        onSelect={() => {
          onSelect()
        }}
        onShow={() => {
          setChildVisiblity(!childVisible)
        }}
        childVisible={childVisible}
        activeTab={activeTab}
        onActiveTab={onActiveTab}
        industryType={industryType}
        totalDepthCount={totalDepthCount}
        totalWidthCount={totalWidthCount}
      />

      {childVisible &&
        data.subTeams &&
        data.subTeams.map((tree) => (
          <TreeComponent
            key={tree.subTeamId || tree.teamId}
            activeTab={activeTab}
            data={tree}
            onAdd={onAdd}
            onDelete={onDelete}
            onSelect={onSelect}
            onActiveTab={onActiveTab}
            child={child}
            isAdmin={isAdmin}
            industryType={industryType}
            totalDepthCount={totalDepthCount}
            totalWidthCount={totalWidthCount}
          />
        ))}
    </>
  )
}
type TreeNodeProps = {
  node: Tree
  onAdd: Function
  onDelete: Function
  onSelect: Function
  activeTab: string
  onActiveTab: Function
  child: number
  isAdmin: boolean
  industryType: number
  onShow: Function
  totalDepthCount: number
  totalWidthCount: number
  childVisible: boolean
}

const TreeNode: FC<React.PropsWithChildren<TreeNodeProps>> = ({
  node,
  onAdd,
  onDelete,
  onSelect,
  activeTab,
  onActiveTab,
  child,
  isAdmin,
  industryType,
  onShow,
  totalDepthCount,
  totalWidthCount,
  childVisible,
}) => {
  const currentUser = useAuthUser()
  const { isVerifiedUser } = useContext(AuthProvider)

  const hasChild = node.subTeams && node.subTeams.length > 0 ? true : false
  const labelRef = useRef(null)
  const { t } = useTranslation()
  return (
    <li
      ref={labelRef}
      style={{
        marginLeft: `${child * 5}px`,
        fontSize: ` ${15 - child > 12 ? 15 - child : 12}px`,
      }}
      className="side-bar flex-row border-0 pr-4 flex justify-between"
      onClick={(e) => {}}
    >
      <button
        onClick={() => {
          onActiveTab(
            node.subTeamId || node.teamId,
            node.currentUserRole,
            node.name,
            node.slug
          )
          labelRef?.current.click()
          onSelect()
        }}
        className={`
         ${
           (child === 1 && node.teamId === activeTab) || node.slug === activeTab
             ? ' bg-base-200'
             : ''
         }
         py-2 flex-grow items-center d-tree-head  hover:bg-base-300  cursor-pointer w-[60%]`}
      >
        <div
          className={` ${
            (child === 1 && node.teamId === activeTab) ||
            node.slug === activeTab
              ? 'text-blue-500'
              : ''
          } flex items-center  `}
        >
          <div className="flex">
            {child === 2 ? (
              <div
                className="pr-1"
                style={{ width: '30px', overflowX: 'hidden' }}
                onClick={() => onShow()}
              >
                {industryType === 1 ? (
                  <IndustryAcademic height="17px" />
                ) : industryType === 2 ? (
                  <IndustryGovernment height="10px" />
                ) : (
                  industryType === 3 && <IndustryBanking height="17px" />
                )}
              </div>
            ) : hasChild ? (
              <button className=" focus:outline-none" onClick={() => onShow()}>
                <ToggleIcon className="p-1" open={childVisible} />
              </button>
            ) : (
              <div className="mr-4 ml-2 "></div>
            )}
          </div>
          <div
            className={node.slug === activeTab ? 'text-blue-500 ' : ''}
            style={{
              overflowWrap: 'break-word',
              maxWidth: '12rem',
              textAlign: 'left',
              padding: '6px',
            }}
          >
            {node.teamName || node.name}
          </div>
        </div>
      </button>
      <>
        {isVerifiedUser && (isAdmin || node.currentUserRole === 'Teacher') && (
          <div className=" shrink-0 flex">
            <Tooltip
              interactive={true}
              click={true}
              toolTipContent={
                <ul className="shadow  menu bg-base-200 rounded-box w-40 bg-red">
                  {node.subTeams.length < totalWidthCount &&
                  child - 1 <= totalWidthCount ? (
                    <li>
                      <a
                        onClick={() => {
                          onAdd(node.subTeamId)
                        }}
                      >
                        {t('add')}
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a onClick={() => toaster.failure('Depth limit exceed')}>
                        {t('add')}
                      </a>
                    </li>
                  )}
                  {node.subTeamId && (
                    <>
                      {(node.createdBy === currentUser.id || isAdmin) && (
                        <li>
                          <a
                            onClick={() =>
                              node.subTeams?.length !== 0
                                ? toaster.failure(t('remove_subTeam_error'))
                                : onDelete(node.subTeamId)
                            }
                            className=" w-full"
                          >
                            {t('delete')}
                          </a>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              }
            >
              <div className="">
                <DotsHorizontal></DotsHorizontal>
              </div>
            </Tooltip>
          </div>
        )}
      </>
    </li>
  )
}

export default TreeComponent
