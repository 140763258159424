import { UserShortProfile } from '@lib/types'
import getUserFullName from '@lib/utils/getUserFullName'
import { vuriloAvatarImageLoader } from '@lib/utils/image-loader'
import sanytizeString from '@lib/utils/sanytizeString/sanytizeString'
import initials from 'initials'
import Image from 'next/legacy/image'
import { FC, MutableRefObject, useRef } from 'react'

interface Props {
  user: UserShortProfile
  size: 'small' | 'medium' | 'large' | 'xs' | 'lg-md' | 'md-lg'
  className?: string
}

const Avatar: FC<React.PropsWithChildren<Props>> = ({
  user,
  size = 'small',
  className,
}) => {
  let ref = useRef() as MutableRefObject<HTMLInputElement>
  const fullName = sanytizeString(user.displayName || getUserFullName(user))

  let nameInitials = initials(fullName)
  nameInitials =
    nameInitials.length < 3
      ? nameInitials
      : nameInitials[0] + nameInitials[nameInitials.length - 1]
  let classWidth = 'w-10 h-10'
  switch (size) {
    case 'small':
      classWidth = 'w-10 h-10'
      break
    case 'medium':
      classWidth = 'w-16 h-16'
      break
    case 'large':
      classWidth = 'w-48 h-48'
      break
    case 'xs':
      classWidth = 'w-4 h-4'
      break
    case 'lg-md':
      classWidth = ' h-[110px] w-[120px]'
      break
    case 'md-lg':
      classWidth = ' h-[83px] w-[83px]'
      break
  }
  return (
    <>
      {' '}
      {user.imageUrl && user.imageUrl !== 'null' && user.imageUrl !== '' ? (
        <div ref={ref} aria-label={fullName} className=" avatar">
          <div
            className={`bg-base-100 relative rounded-full ${classWidth} ${className}`}
          >
            <Image
              src={user.imageUrl}
              loader={vuriloAvatarImageLoader}
              alt={fullName}
              layout={'fill'}
              className="rounded-full"
            />
          </div>
        </div>
      ) : (
        <div ref={ref} aria-label={fullName} className=" avatar  placeholder">
          <div
            className={`bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-neutral-content rounded-full ${classWidth} ${className}`}
          >
            <span className="uppercase">{nameInitials}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default Avatar
