import { LoadingDots } from '@components/ui'
import cn from 'classnames'
import { useAuthUser } from 'next-firebase-auth'
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import s from './Button.module.css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?: 'flat' | 'slim' | 'outline'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = forwardRef(
  (props, buttonRef) => {
    const currentUser = useAuthUser()

    const {
      className,
      variant = 'flat',
      children,
      active,
      width,
      loading = false,
      disabled = false,
      style = {},
      Component = 'button',
      ...rest
    } = props
    const ref = useRef<typeof Component>(null)

    const rootClassName = cn(s.root, className, {
      [s.slim]: variant === 'slim',
      [s.outline]: variant === 'outline',
      [s.loading]: loading,
      [s.disabled]: disabled,
    })

    return (
      <Component
        aria-pressed={active}
        data-variant={variant}
        ref={mergeRefs([ref, buttonRef])}
        className={rootClassName}
        disabled={disabled || loading}
        style={{
          width,
          ...style,
        }}
        {...rest}
      >
        {children}
        {loading && (
          <i className="pl-2 m-0 flex">
            <LoadingDots />
          </i>
        )}
      </Component>
    )
  }
)

export default Button
