import { Moon, Sun } from '@components/icons'
import analytics from '@lib/analytics'
import cn from 'classnames'
import { useTheme } from 'next-themes'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import s from './ThemeSwitch.module.css'

interface Props {
  onClickCallback: Function
}
const ThemeSwitch: FC<React.PropsWithChildren<Props>> = ({
  onClickCallback,
}) => {
  const { t } = useTranslation()
  const { resolvedTheme: theme, setTheme } = useTheme()

  const [mounted, setMounted] = useState(false)
  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), [])

  if (!mounted) return null
  return (
    <div>
      <a
        className={cn(s.link, 'justify-between')}
        onClick={() => {
          theme === 'dark' ? setTheme('light') : setTheme('dark')
          analytics.track('theme switched', {
            location: 'footer',
            theme,
          })
          onClickCallback(false)
        }}
      >
        <div>
          {t('theme')}: <strong>{t(theme)}</strong>{' '}
        </div>
        <div className="ml-3">
          {theme === 'dark' ? (
            <Moon width={20} height={20} />
          ) : (
            <Sun width="20" height={20} />
          )}
        </div>
      </a>
    </div>
  )
}

export default ThemeSwitch
