declare global {
  interface Window {
    umami: any
  }
}
export default function umamiPlugin() {
  return {
    /* All plugins require a name */
    name: 'umami-plugin',
    initialize: ({ config }) => {
      // load umami script to page
    },
    page: ({ payload }) => {
      // call umami specific page tracking
    },
    track: ({ payload }) => {
      typeof window !== 'undefined' &&
        window.umami.trackEvent(
          JSON.stringify(payload.properties),
          payload.event
        )
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && !!window.umami
    },
  }
}
