import React, { FC } from 'react'
import useClipboard from 'react-use-clipboard'

const CopyToClipboard: FC<
  React.PropsWithChildren<{
    code: string
    className: string
  }>
> = ({ code, className }) => {
  const [isCopied, setCopied] = useClipboard(code, { successDuration: 1000 })
  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        setCopied()
      }}
      aria-label="Copy code to clipboard"
      className={` p-2 absolute z-10 top-4 right-3 text-gray-400 hover:text-brand-light dark:hover:text-white focus:outline-none transition-all outline-none duration-100 ease-in-out ${className}`}
    >
      {isCopied ? (
        //   prettier-ignore
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="text-accent"><g fill="none"><path d="M5 13l4 4L19 7" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /></g></svg>
      ) : (
        // prettier-ignore
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><g strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" fill="none" stroke="currentColor"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></g></svg>
      )}
    </button>
  )
}

export default CopyToClipboard
