import cn from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import s from './Select.module.css'

export interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  className?: string
  categories: any[]
  nameProp: string
  valueProp: string
  placeholder: string
}

const Select: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t } = useTranslation()

  const { className, categories, nameProp, valueProp, placeholder, ...rest } =
    props

  const rootClassName = cn(s.root, ' select  select-bordered', className)

  return (
    <select
      className={rootClassName}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      // @ts-ignore
      placeholder={placeholder}
      {...rest}
    >
      <option value="">{t(placeholder)}</option>
      {categories &&
        categories?.map((category, index) => (
          <option key={index} value={category[valueProp]}>
            {t(category[nameProp])}
          </option>
        ))}
    </select>
  )
}

export default Select
