import { FacebookChat, Head, Layout } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { LogRocketProvider } from '@lib/hooks/use-logrocket'
import usePersistLocaleCookie from '@lib/hooks/usePersistLocaleCookie'
import toaster from '@lib/utils/notify'
import '@styles/chrome-bug.css'
import '@styles/globals.css'
import initAuth from '@utils/initAuth' // the module you created above
import { AppProps } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next/router'
import Script from 'next/script'
import Notiflix from 'notiflix'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

export default function MyApp({ Component, pageProps }: AppProps) {
  initAuth()
  const AppLayout = (Component as any).Layout || Layout
  const queryClientRef = React.useRef<QueryClient>()
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }

  const router = useRouter()
  usePersistLocaleCookie()

  useEffect(() => {
    document.body.classList?.remove('loading')
    // Notify
    toaster.init()

    // Report
    Notiflix.Report.init({ svgSize: '0px', borderRadius: '0px' })

    // Confirm
    Notiflix.Confirm.init({
      borderRadius: '0px',
    })

    // Loading
    Notiflix.Loading.init({})

    NProgress.configure({ showSpinner: false })
  }, [])

  useEffect(() => {
    const routeChangeStart = async (url) => {
      NProgress.start()
    }
    const routeChangeComplete = () => NProgress.done()
    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)
    router.events.on('routeChangeError', routeChangeComplete)
    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
      router.events.off('routeChangeError', routeChangeComplete)
    }
  }, [])

  return (
    <>
      <Head />
      {process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT == 'production' && (
        <Script
          data-website-id="c0da37fa-395d-46eb-a424-082307a1f77b"
          src="https://count.vurilo.com/umami.js"
        ></Script>
      )}
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <ManagedUIContext>
            <LogRocketProvider>
              <AppLayout pageProps={pageProps}>
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </AppLayout>
              {(process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT ==
                'production' ||
                process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT ==
                  'staging') && <FacebookChat></FacebookChat>}
              <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
            </LogRocketProvider>
          </ManagedUIContext>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}
