import { FC } from 'react'

interface Props {
  className?: string
}
const EmailIcon: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 22"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Email</title>

      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmailIcon
