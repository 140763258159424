import { startsWith } from 'lodash'
import imagekit from './imagekit-client'

function normalizeSrc(src: string) {
  return src[0] === '/' ? src.slice(1) : src
}

const vuriloImageLoader = (props): string => {
  let paramsString = ''
  let { src, width, quality } = props
  if (startsWith(src, 'imagekit.io')) {
    return imagekit.url({
      path: src,
      transformation: [
        {
          width: width,
          quality,
        },
      ],
    })
  }
  return `${normalizeSrc(src)}${paramsString}`
}

export const vuriloAvatarImageLoader = (props): string => {
  let paramsString = ''
  let { src, width, quality } = props
  if (src && src.indexOf('imagekit.io') > 0) {
    return imagekit.url({
      path: src.replace(process.env.NEXT_PUBLIC_IMAGE_KIT_ENDPOINT_URL, ''),
      transformation: [
        {
          width,
          height: width,
          quality,
          radius: 'max',
          focus: 'auto',
        },
      ],
    })
  }
  return `${normalizeSrc(src)}${paramsString}`
}

export const vuriloThumbnailImageLoader = (props): string => {
  let paramsString = ''
  let { src, width, quality } = props
  if (startsWith(src, 'imagekit.io')) {
    return imagekit.url({
      path: src,
      transformation: [
        {
          width,
          quality,
          ar: '4:3',
        },
      ],
    })
  }
  return `${normalizeSrc(src)}${paramsString}`
}

export default vuriloImageLoader
