import { BottomFooter, I18nWidget } from '@components/common'
import { Facebook, Instagram, Youtube } from '@components/icons'
import DownloadApps from '@components/icons/DownloadApps'
import EmailIcon from '@components/icons/EmailIcon'
import Location from '@components/icons/Location'
import Telephone from '@components/icons/Telephone'
import WhatsApp from '@components/icons/WhatsApp'
import { Container, Logo } from '@components/ui'
import analytics from '@lib/analytics'
import cn from 'classnames'
import Link from 'next/link'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import s from './Footer.module.css'

interface Props {
  className?: string
  children?: any
}
const Footer: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const { t } = useTranslation()
  const rootClassName = cn(className)

  const legalPages = [
    {
      url: '/about',
      title: t('about'),
    },
    {
      url: '/terms',
      title: t('terms'),
    },
    {
      url: '/privacy',
      title: t('privacy'),
    },
  ]

  return (
    <footer className={rootClassName}>
      <Container className="bg-base-100 text-base-content">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 py-12  transition-colors duration-150">
          <div className="col-span-1 lg:col-span-4 ">
            <Link
              href="/"
              className="flex flex-initial items-center font-bold md:mr-24"
            >
              <span className="mr-2">
                <Logo />
              </span>
            </Link>
            <div className="text-base-content pt-3">
              <ul className="flex flex-initial flex-col md:flex-1">
                <li className="flex py-3 md:py-0 md:pb-3">
                  <Telephone className="h-5 w-6"></Telephone>
                  <a href="tel:01-4543720" className="ml-1">
                    {' '}
                    {t('Tel')}: 01-4543720
                  </a>
                </li>
                <li className="flex py-3 md:py-0 md:pb-3">
                  <EmailIcon className="h-5 w-6"></EmailIcon>
                  <a
                    href="mailto:support@vurilo.com"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-1"
                  >
                    {' '}
                    {t('email')}: support@vurilo.com
                  </a>
                </li>
                <li className="flex py-3 md:py-0 md:pb-3">
                  <WhatsApp className="h-5 w-6"></WhatsApp>
                  <a
                    href="https://wa.me/9779801230314"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-1"
                  >
                    {' '}
                    {t('Whatsapp')}: +977 980-1230314
                  </a>
                </li>
                <li className="flex py-3 md:py-0 md:pb-3">
                  <Location className="h-5 w-6"></Location>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/8bPEtihXzppYTem1A"
                    className="ml-1"
                  >
                    {t('Location')} : {t('place')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3">
            <ul className="flex flex-initial flex-col md:flex-1">
              <li className="py-3 md:py-0 md:pb-4">
                <Link
                  href="/"
                  className="transition ease-in-out duration-150"
                  onClick={() => {
                    analytics.track('home_link_clicked', {
                      location: 'footer',
                    })
                  }}
                >
                  {t('home')}
                </Link>
              </li>
              <li className="py-3 md:py-0 md:pb-4">
                <Link
                  href="https://www.vurilo.com/teacher"
                  className="transition ease-in-out duration-150"
                  onClick={() => {
                    analytics.track('become_instructor_link_clicked', {
                      location: 'footer',
                    })
                  }}
                >
                  {t('become_instructor')}
                </Link>
              </li>
              {/* <li className="py-3 md:py-0 md:pb-4">
                <Link href="/careers">
                  <a
                    className="transition ease-in-out duration-150"
                    onClick={() => {
                      analytics.track('careers_link_clicked', {
                        location: 'footer',
                      })
                    }}
                  >
                    Careers
                  </a>
                </Link>
              </li> */}
              <li className="py-3 md:py-0 md:pb-4">
                <Link
                  href="/contact"
                  className="transition ease-in-out duration-150"
                  onClick={() => {
                    analytics.track('contact_link_clicked', {
                      location: 'footer',
                    })
                  }}
                >
                  {t('contact')}
                </Link>
              </li>
              <li className="py-3 md:py-0 md:pb-4">
                <Link
                  href="https://blog.vurilo.com"
                  className="transition ease-in-out duration-150"
                  onClick={() => {
                    analytics.track('blogs_link_clicked', {
                      location: 'footer',
                    })
                  }}
                >
                  {t('blog')}
                </Link>
              </li>
              {/* {sitePages.map((page) => (
                <li key={page.url} className="py-3 md:py-0 md:pb-4">
                  <Link href={page.url!}>
                    <a className="transition ease-in-out duration-150">
                      {page.name}
                    </a>
                  </Link>
                </li>
              ))} */}
            </ul>
          </div>
          <div className="col-span-1 lg:col-span-2">
            <ul className="flex flex-initial flex-col md:flex-1">
              {legalPages.map((page) => (
                <li key={page.url} className="py-3 md:py-0 md:pb-4">
                  <Link
                    href={page.url}
                    className=" transition ease-in-out duration-150"
                    onClick={() => {
                      analytics.track(`${page.title}_link_clicked`, {
                        location: 'footer',
                        title: page.title,
                      })
                    }}
                  >
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-1 lg:col-span-3 flex justify-start lg:justify-end">
            <div className="flex flex-col  space-y-7">
              <div className="flex space-x-6 items-center h-10  lg:justify-end">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Official Facebook Page"
                  href="https://facebook.com/vuriloapp"
                  className={s.link}
                  onClick={() => {
                    analytics.track(`facebook_link_clicked`, {
                      location: 'footer',
                    })
                  }}
                >
                  <Facebook />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Official Youtube"
                  href="https://www.youtube.com/channel/UCRg-CRRmgnHB6dUL0TqypXQ"
                  className={s.link}
                  onClick={() => {
                    analytics.track(`youtube_link_clicked`, {
                      location: 'footer',
                    })
                  }}
                >
                  <Youtube />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Official Instagram"
                  href="https://instagram.com/vuriloapp"
                  className={s.link}
                  onClick={() => {
                    analytics.track(`instagram_link_clicked`, {
                      location: 'footer',
                    })
                  }}
                >
                  <Instagram />
                </a>
                <I18nWidget />
              </div>
              <div className="flex flex-col  items-center space-y-2">
                <span>{t('app')}</span>
                {/* <div className="flex flex-col md:flex-row text-primary space-y-2 md:space-y-0 md:space-x-4"> */}
                <Link href={'/app'}>
                  <DownloadApps />
                </Link>
                {/* <Link href="https://bit.ly/3D8xIOe">
                    <a
                      className={s.link}
                      onClick={() => {
                        analytics.track(`app_download_icon_clicked`, {
                          location: 'footer',
                          type: 'ios',
                        })
                      }}
                    >
                      <DesktopAppIcon />
                    </a>
                  </Link>
                  <Link href="https://play.google.com/store/apps/details?id=com.vurilo.app">
                    <a
                      className={s.link}
                      onClick={() => {
                        analytics.track(`app_download_icon_clicked`, {
                          location: 'footer',
                          type: 'android',
                        })
                      }}
                    >
                      <PlayStoreIcon />
                    </a>
                  </Link> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className=" divider my-0"></div>
        <BottomFooter></BottomFooter>
      </Container>
    </footer>
  )
}

export default Footer
