import { SearchBar, UserNav } from '@components/common'
import { Container, Logo } from '@components/ui'
import { useMyTeams } from '@lib/hooks/useServices'
import { updateActiveTeam } from '@lib/services'
import toaster from '@lib/utils/notify'
import Cookies from 'js-cookie'
import { useAuthUser, withAuthUser } from 'next-firebase-auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import s from './Navbar.module.css'
import NavbarRoot from './NavbarRoot'
interface Props {
  //currentUser: AuthUser
}
const Navbar: FC<React.PropsWithChildren<Props>> = () => {
  const currentUser = useAuthUser()
  const router = useRouter()
  const isSearch = router.pathname.includes('/search')
  const { t } = useTranslation()
  const locale = router.locale

  const { data, isSuccess } = useMyTeams(currentUser, locale as string)

  const activeTeam = useMemo(() => {
    if (data && isSuccess) {
      const activeTeam = data?.find((e) => e.isActive)

      Cookies.set('team-id', activeTeam ? activeTeam.id : '')
      return activeTeam
    }
  }, [data, isSuccess])

  const handleTeamChange = async (id: string) => {
    try {
      await updateActiveTeam(locale, currentUser, id)
      Cookies.set('team-id', id)
      window.location.reload()
    } catch (err) {
      toaster.failure(err)
    }
  }

  return (
    <NavbarRoot>
      <Container>
        <div className="relative flex flex-row justify-between py-3 align-center">
          <div className="flex items-center flex-1">
            {currentUser.id ? (
              <>
                <Link href="/dashboard" aria-label="Logo">
                  {activeTeam?.logoUrl ? (
                    <img width="70" height="21" src={activeTeam.logoUrl} />
                  ) : (
                    <Logo />
                  )}
                </Link>

                <div className="dropdown w-[50%]">
                  <label
                    tabIndex={0}
                    className="btn btn-ghost rounded-btn ml-2 focus:outline-none"
                  >
                    <div className="flex">
                      <span className=" line-clamp-1 text-xs mr-2">
                        {activeTeam?.name ? activeTeam.name : 'Vurilo'}
                      </span>
                      <div>
                        <svg
                          fill="currentColor"
                          height="10"
                          width="10"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 330 330"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {' '}
                            <path
                              id="XMLID_225_"
                              d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                            ></path>{' '}
                          </g>
                        </svg>
                      </div>
                    </div>
                  </label>

                  <div
                    tabIndex={0}
                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-72  relative "
                  >
                    <ul className="max-h-80 overflow-y-scroll scrollbar">
                      <li className="my-2">
                        <a
                          className={`line-clamp-3 hover:bg-primary ${
                            !activeTeam && 'bg-primary'
                          }`}
                          onClick={() => handleTeamChange('')}
                        >
                          Vurilo
                        </a>
                      </li>
                      {data?.map((e) => (
                        <li key={e.id}>
                          <a
                            className={`line-clamp-3 hover:bg-primary  ${
                              activeTeam?.id === e.id && 'bg-primary'
                            }`}
                            onClick={() => handleTeamChange(e.id)}
                          >
                            {e.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {/* <div className="flex flex-nowrap mt-2 flex-row">
                      <input
                        type="text"
                        placeholder="Code"
                        className="input w-full max-w-xs mr-4 cursor-text input-bordered"
                      />

                      <Button variant="outline" type="submit">
                        Join
                      </Button>
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <Link href="/" className={s.logo} aria-label="Logo">
                <Logo />
              </Link>
            )}

            <nav className="hidden ml-6 space-x-4 lg:block menu">
              <Link href="/search" className={s.link}>
                {t('browse')}
              </Link>
            </nav>
          </div>
          {!isSearch && (
            <div className="justify-center flex-1 hidden lg:flex">
              <SearchBar />
            </div>
          )}
          <div className="flex justify-end flex-1 space-x-8">
            <UserNav currentUser={currentUser} />
          </div>
        </div>
        {!isSearch && (
          <div className="flex pb-4 lg:px-6 lg:hidden">
            <SearchBar id="mobile-search" />
          </div>
        )}
      </Container>
    </NavbarRoot>
  )
}

export default withAuthUser()(Navbar)
