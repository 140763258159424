const PrismTheme = {
  'code[class*="language-"]': {
    fontFamily:
      '"Fira Mono", Menlo, Monaco, "Lucida Console", "Courier New", Courier, monospace',
    fontSize: '16px',
    lineHeight: '1.375',
    direction: 'ltr',
    textAlign: 'left',
    wordSpacing: 'normal',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    background: 'transparent',
    color: '#b9b5b8',
  },
  'pre[class*="language-"]': {
    fontFamily:
      '"Fira Mono", Menlo, Monaco, "Lucida Console", "Courier New", Courier, monospace',
    fontSize: '16px',
    lineHeight: '1.375',
    direction: 'ltr',
    textAlign: 'left',
    wordSpacing: 'normal',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    background: 'transparent',
    color: '#b9b5b8',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
  },
  'pre > code[class*="language-"]': {
    fontSize: '1em',
  },
  ':not(pre) > code[class*="language-"]': {
    padding: '.1em',
    borderRadius: '.3em',
  },
  comment: {
    color: '#797379',
  },
  prolog: {
    color: '#797379',
  },
  doctype: {
    color: '#797379',
  },
  cdata: {
    color: '#797379',
  },
  punctuation: {
    color: '#b9b5b8',
  },
  '.namespace': {
    Opacity: '.7',
  },
  null: {
    color: '#fd8b19',
  },
  operator: {
    color: '#fd8b19',
  },
  boolean: {
    color: '#fd8b19',
  },
  number: {
    color: '#fd8b19',
  },
  property: {
    color: '#fdcc59',
  },
  tag: {
    color: '#1290bf',
  },
  string: {
    color: '#149b93',
  },
  selector: {
    color: '#c85e7c',
  },
  'attr-name': {
    color: '#fd8b19',
  },
  entity: {
    color: '#149b93',
    cursor: 'help',
  },
  url: {
    color: '#149b93',
  },
  '.language-css .token.string': {
    color: '#149b93',
  },
  '.style .token.string': {
    color: '#149b93',
  },
  'attr-value': {
    color: '#8fc13e',
  },
  keyword: {
    color: '#8fc13e',
  },
  control: {
    color: '#8fc13e',
  },
  directive: {
    color: '#8fc13e',
  },
  unit: {
    color: '#8fc13e',
  },
  statement: {
    color: '#149b93',
  },
  regex: {
    color: '#149b93',
  },
  atrule: {
    color: '#149b93',
  },
  placeholder: {
    color: '#1290bf',
  },
  variable: {
    color: '#1290bf',
  },
  important: {
    color: '#dd464c',
    fontWeight: 'bold',
  },
  'pre > code.highlight': {
    Outline: '.4em solid red',
    OutlineOffset: '.4em',
  },
}

export default PrismTheme
