import SvgIcon from './SvgIcon'

const Star = ({ outline = true, half = false, filled = false, ...props }) => {
  return (
    <SvgIcon
      viewBoxWidth={22}
      viewBoxHeight={22}
      title="Star icon"
      className="w-6 h-6"
      {...props}
    >
      $
      {outline && (
        <path
          fill="#8fe6d7"
          d="M16.0034083,8.529 L11.7319704,8.529 C11.5133748,8.529 11.3201199,8.3869989 11.2548088,8.17838809 L10.0009704,4.17349353 L8.74713198,8.17838809 C8.68182083,8.3869989 8.48856592,8.529 8.26997037,8.529 L3.99853245,8.529 L7.38536086,11.0742932 C7.55614708,11.2026436 7.62651678,11.425325 7.56049874,11.6285081 L6.23404463,15.7109246 L9.70706807,13.1874985 C9.88231586,13.0601672 10.1196249,13.0601672 10.2948727,13.1874985 L13.7678961,15.7109246 L12.441442,11.6285081 C12.375424,11.425325 12.4457937,11.2026436 12.6165799,11.0742932 L16.0034083,8.529 Z M12.0993636,7.529 L17.5009704,7.529 C17.9812292,7.529 18.1852864,8.14017643 17.8013609,8.42870683 L13.5028592,11.6591471 L15.1944987,16.8654919 C15.3441762,17.3261529 14.8169229,17.709215 14.4250681,17.4245015 L10.0009704,14.2100447 L5.57687267,17.4245015 C5.18501787,17.709215 4.65776453,17.3261529 4.80744199,16.8654919 L6.49908159,11.6591471 L2.20057987,8.42870683 C1.81665437,8.14017643 2.0207115,7.529 2.50097037,7.529 L7.90257711,7.529 L9.52380876,2.35061191 C9.67016656,1.88312936 10.3317742,1.88312936 10.478132,2.35061191 L12.0993636,7.529 Z"
        />
      )}
      $
      {half && (
        <path
          fill="#8fe6d7"
          d="M10,4.67349353 L10,13.592 C10.1031392,13.592 10.2062784,13.6238328 10.2939023,13.6874985 L13.7669257,16.2109246 L12.4404716,12.1285081 C12.3744536,11.925325 12.4448233,11.7026436 12.6156095,11.5742932 L16.0024379,9.029 L11.731,9.029 C11.5124045,9.029 11.3191495,8.8869989 11.2538384,8.67838809 L10,4.67349353 Z M12.0983933,8.029 L17.5,8.029 C17.9802589,8.029 18.184316,8.64017643 17.8003905,8.92870683 L13.5018888,12.1591471 L15.1935284,17.3654919 C15.3432058,17.8261529 14.8159525,18.209215 14.4240977,17.9245015 L10,14.7100447 L5.5759023,17.9245015 C5.1840475,18.209215 4.65679416,17.8261529 4.80647163,17.3654919 L6.49811122,12.1591471 L2.1996095,8.92870683 C1.815684,8.64017643 2.01974113,8.029 2.5,8.029 L7.90160675,8.029 L9.52283839,2.85061191 C9.6691962,2.38312936 10.3308038,2.38312936 10.4771616,2.85061191 L12.0983933,8.029 Z"
          transform="translate(0 -.5)"
        />
      )}
      $
      {filled && (
        <path
          fill="#8fe6d7"
          d="M17.5009704,7.529 C17.9812292,7.529 18.1852864,8.14017643 17.8013609,8.42870683 L13.5028592,11.6591471 L15.1944987,16.8654919 C15.3441762,17.3261529 14.8169229,17.709215 14.4250681,17.4245015 L10.0009704,14.2100447 L5.57687267,17.4245015 C5.18501787,17.709215 4.65776453,17.3261529 4.80744199,16.8654919 L6.49908159,11.6591471 L2.20057987,8.42870683 C1.81665437,8.14017643 2.0207115,7.529 2.50097037,7.529 L7.90257711,7.529 L9.52380876,2.35061191 C9.67016656,1.88312936 10.3317742,1.88312936 10.478132,2.35061191 L12.0993636,7.529 L17.5009704,7.529 Z"
        />
      )}
    </SvgIcon>
  )
}

export default Star
