import Compressor from 'compressorjs'

const compressImage = async (
  file: File,
  type: 'avatar' | 'thumbnail' | 'general'
) => {
  const options = {
    maxSizeMB: 0.1,
    // maxWidthOrHeight: type === 'thumbnail' ? 1280 : 200,
    maxWidth: type === 'thumbnail' ? 1280 : 200,
    // useWebWorker: true,
    initialQuality: 0.8,
  }
  switch (type) {
    case 'avatar':
      options.maxWidth = 200
      break
    case 'thumbnail':
      options.maxWidth = 1280
      break
    case 'general':
      options.maxWidth = 640
      break
  }
  const cmpFile = () => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        ...options,
        success: (file) => {
          resolve(file)
        },
        error: (err) => reject(err),
      })
    })
  }
  const img = await cmpFile()
  return img
}

export default compressImage
