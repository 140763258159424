// import Tippy, { TippyProps } from '@tippyjs/react/headless';
import Tippy from '@tippyjs/react'

const TextTooltip = ({ toolTipContent, children, interactive = false }) => {
  return (
    <>
      {children ? (
        <Tippy
          content={
            <div className="shadow  menu bg-base-300 rounded-box text-base-content">
              <span className="p-4">{toolTipContent}</span>
            </div>
          }
          interactive={interactive}
        >
          {children}
        </Tippy>
      ) : (
        ''
      )}
    </>
  )
}

export default TextTooltip
