const DownloadApps = () => {
  const color = 'currentColor'
  return (
    <>
      <span className="sr-only"> Download Our apps</span>

      <svg
        width="150"
        height="65"
        viewBox="0 0 150 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99036 1H143.01C144.595 1.00402 146.115 1.91168 147.237 3.52475C148.36 5.13782 148.993 7.32522 149 9.60919V55.3908C148.993 57.6748 148.36 59.8622 147.237 61.4752C146.115 63.0883 144.595 63.996 143.01 64H6.99036C5.40499 63.996 3.88513 63.0883 2.76275 61.4752C1.64036 59.8622 1.00663 57.6748 1 55.3908V9.60919C1.00663 7.32522 1.64036 5.13782 2.76275 3.52475C3.88513 1.91168 5.40499 1.00402 6.99036 1Z"
          stroke={color}
          strokeWidth="0.840937"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3548 33.4306C50.3686 32.3112 50.6733 31.2132 51.2404 30.2386C51.8067 29.264 52.6176 28.4441 53.5966 27.8548C52.9767 26.9895 52.1577 26.277 51.2038 25.774C50.2508 25.2711 49.1896 24.9916 48.1058 24.958C45.7958 24.7204 43.5566 26.3074 42.3793 26.3074C41.1792 26.3074 39.3689 24.9812 37.4165 25.0206C36.1531 25.062 34.9213 25.4213 33.8431 26.0636C32.7641 26.7059 31.8761 27.6092 31.2634 28.6852C28.6025 33.1793 30.5874 39.7897 33.1363 43.4242C34.4119 45.2068 35.8988 47.1951 37.8536 47.1231C39.7614 47.0467 40.4731 45.935 42.7766 45.935C45.0597 45.935 45.7268 47.1231 47.7182 47.0793C49.7641 47.0467 51.0543 45.2908 52.2861 43.4928C53.2026 42.2237 53.907 40.8223 54.375 39.3401C53.1839 38.8465 52.1674 38.0232 51.4541 36.9726C50.7399 35.9221 50.3596 34.6906 50.3596 33.4315"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5977 22.5638C47.7141 21.2529 48.2634 19.5718 48.1293 17.875C46.4222 18.0509 44.846 18.8476 43.7174 20.1053C42.5994 21.3481 42.0339 22.9708 42.1436 24.6183C42.9984 24.6266 43.8434 24.4456 44.6152 24.0888C45.3863 23.7321 46.0639 23.2091 46.5969 22.5596"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.5138 18.0512L107.862 32.5222L92.5877 47.1204C92.3431 46.9287 92.153 46.6828 92.0328 46.4046C91.9125 46.1262 91.867 45.8242 91.9003 45.5252L91.7614 19.6141C91.7248 19.3126 91.7752 19.0074 91.9076 18.7311C92.0401 18.4547 92.2497 18.2178 92.5138 18.0457V18.0512ZM112.924 27.6841L94.2606 18.0678C94.0575 17.9663 93.8365 17.901 93.6082 17.875L108.497 31.9151L112.925 27.6841H112.924ZM113.755 36.8722L109.137 32.5175L113.749 28.1075L120.103 31.3791C121.667 32.1845 120.866 33.1919 120.103 33.5878L113.755 36.8722ZM93.8536 47.125C93.9933 47.0745 94.129 47.015 94.2606 46.9469L112.929 37.2975L108.501 33.1246L93.8536 47.125Z"
          fill={color}
        />
      </svg>
    </>
  )
}

export default DownloadApps
