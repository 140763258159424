import { LoadingDots } from '@components/ui'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UseInfiniteQueryResult } from 'react-query'

const InfiniteLoadMore: FC<
  React.PropsWithChildren<{
    query: UseInfiniteQueryResult
    loadMoreButtonRef: React.MutableRefObject<undefined>
  }>
> = ({ query, loadMoreButtonRef }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`flex justify-center cursor-pointer text-primary mt-4 ${
        query.hasNextPage ? 'block' : 'hidden'
      }`}
    >
      <button
        className="text-xs"
        ref={loadMoreButtonRef}
        onClick={() => query.fetchNextPage()}
        disabled={!query.hasNextPage || query.isFetchingNextPage}
      >
        {query.isFetchingNextPage ? (
          <span>
            {t('Loading')}
            <LoadingDots></LoadingDots>
          </span>
        ) : (
          <button className="btn btn-outline btn-success">Load More</button>
        )}
      </button>
    </div>
  )
}

export default InfiniteLoadMore
