import { Time } from '@components/ui'
import { Course } from '@lib/types'
import getCourseOgImageUrl from '@lib/utils/getCourseOgImageUrl'
import { convertTimeWithTitles, formatTimeAgo } from '@lib/utils/time-utils'
import cn from 'classnames'
import { useTheme } from 'next-themes'
import Image from 'next/legacy/image'
import React, { FC, MouseEventHandler } from 'react'
import Avatar from '../Avatar'
import s from './CourseTile.module.css'
interface Props {
  course: Course
  onClick: MouseEventHandler
  className?: string
  fileIndex?: string
}
const CourseTile: FC<React.PropsWithChildren<Props>> = ({
  course,
  onClick,
  className,
  fileIndex,
}) => {
  const { resolvedTheme: theme, setTheme } = useTheme()
  const courseAuthor = course.teachers.filter((x) => x.isAuthor)[0]

  return (
    <div className={`${cn(s.root, className)} h-full mt-2`} onClick={onClick}>
      <div
        style={{ height: '90%' }}
        className="rounded-md card  card-bordered shadow-lg bg-base-200"
      >
        <div>
          <Image
            width="460"
            height="252"
            src={getCourseOgImageUrl(
              courseAuthor,
              course.name,
              course.thumbnail,
              theme
            )}
            alt={course.name}
            className="rounded-t-md"
            layout="responsive"
          ></Image>
        </div>
        <div className="p-4  card-body">
          <div className={cn(s.body)} style={{ minHeight: '30px' }}>
            <h2 className="text-lg  card-title leading-tight line-clamp-1 font-bold title-font mb-2">
              {course.name}
            </h2>
          </div>
          <div className="inline-flex items-center leading-none text-sm">
            <Avatar user={courseAuthor} size={'small'}></Avatar>{' '}
            <span className="ml-2">
              {courseAuthor.firstName} {courseAuthor.lastName}
            </span>
          </div>
          {course.percentage !== undefined && (
            <>
              {course.percentage !== 100 && course.percentage !== 0 ? (
                <div className="flex justify-center items-center">
                  <progress
                    value={`${course.percentage}`}
                    max="100"
                    className=" progress  progress-primary grow"
                  ></progress>
                  {
                    <span className="text-xs pl-2 ">
                      {course.percentage + '%'}
                    </span>
                  }
                </div>
              ) : (
                <span className="my-2"></span>
              )}
            </>
          )}
          <div className="text-center align-baseline mt-2 leading-none flex justify-between w-full">
            <span className="inline-flex items-center leading-none text-sm  py-1 ">
              <Time className="w-4 h-4 text-primary"></Time>&nbsp;
              {course.percentage !== undefined && fileIndex !== 'recording' ? (
                <span className="text-xs">
                  {course.percentage === 100
                    ? 'Completed'
                    : course.percentage === 0
                    ? 'Enrolled'
                    : 'Watched'}
                  {' ' + formatTimeAgo(course.lastUpdated)}
                </span>
              ) : (
                convertTimeWithTitles(course.duration, { showSeconds: true })
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseTile
