import {
  Button,
  FormErrorMessageBox,
  Input,
  InputPassword,
  Logo,
} from '@components/ui'
import { WithLogin } from '@lib/hoc/LoginHoc'
import { Field, Form, Formik } from 'formik'
import Link from 'next/link'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface Props {
  type: 'modal' | 'page'
  handelLogin: Function
  onSignInWithGoogle: Function
  onSignInWithApple: Function
  message: String
  loading: boolean
  onPhoneNumberSubmit: Function
  showOtp: boolean
  onSignInWithOtp: Function
  setSignInWithPhone: Function
  handleForgotPasswordLink: Function
}

const initialValues = {
  email: '',
  password: '',
}

const LoginWithEmailAndPassword: FC<Props> = ({
  handelLogin,
  message,
  loading,
  handleForgotPasswordLink,
}) => {
  const { t } = useTranslation()
  const fieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.focus()
    }
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required('email_req_msg')
          .label('Email')
          .email('valid_email_msg'),
        password: Yup.string().required('password_required').label('password'),
        // .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'valid_password_msg'),
      })}
      onSubmit={(data, { setFieldValue }) => handelLogin(data, setFieldValue)}
    >
      {({ handleSubmit }) => (
        <>
          <Form
            onSubmit={handleSubmit}
            className="sm:w-80 flex flex-col sm:justify-between"
          >
            <Link href={'/'}>
              <div className="flex justify-center sm:pb-5 ">
                <Logo width="64px" height="64px" className="cursor-pointer" />
              </div>
            </Link>
            <div className="flex flex-col md:space-y-3 ">
              <div className="relative">
                {message && (
                  <div className="text-error border border-error p-3">
                    {message} {t('did_you')} {` `}
                    <a
                      className=" inline font-bold hover:underline cursor-pointer"
                      onClick={() => handleForgotPasswordLink()}
                    >
                      {t('forget_pw')}
                    </a>
                  </div>
                )}
              </div>
              <div className=" form-control pt-2 pb-4">
                <div className="relative">
                  <Field
                    innerRef={fieldRef}
                    name="email"
                    as={Input}
                    type="email"
                    placeholder={t('email')}
                    autoComplete="username"
                  />
                  <span className="absolute flex tracking-tight">
                    <FormErrorMessageBox name={'email'}></FormErrorMessageBox>
                  </span>
                </div>
              </div>
              <div className=" form-control pt-3 pb-4">
                <div className="relative">
                  <Field
                    as={InputPassword}
                    name="password"
                    placeholder={t('password')}
                    autoComplete="current-password"
                  />
                  <span className="absolute flex tracking-tight">
                    <FormErrorMessageBox
                      name={'password'}
                    ></FormErrorMessageBox>
                  </span>
                </div>
              </div>
              <span className="pt-3" />
              <Button
                variant="slim"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                {t('log')}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default WithLogin(LoginWithEmailAndPassword)
