import { Config } from '@config/constants'

export const fetchCategories = (locale: string) => {
  return fetch(
    `${Config.API_BASE_URL}/api/category?size=0&CategoryScopeTypeEnum=2`,
    {
      headers: {
        'Accept-Language': locale,
      },
    }
  ).then((r) => r.json())
}

export const fetchSubCategories = (categoryId: string, locale: string) => {
  return fetch(
    `${Config.API_BASE_URL}/api/subCategory/category/${categoryId}`,
    {
      headers: {
        'Accept-Language': locale,
      },
    }
  ).then((r) => r.json())
}
