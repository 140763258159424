import cn from 'classnames'
import React, { FC } from 'react'

interface Props {
  className?: string
  children?: any
  el?: HTMLElement
  clean?: boolean
}

const Container: FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  el = 'div',
  clean,
}) => {
  const rootClassName = cn(className, {
    'mx-auto max-w-8xl lg:px-6 px-2': !clean,
  })

  let Component: React.ComponentType<
    React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
  > = el as any
  // @ts-ignore
  return <Component className={rootClassName}>{children}</Component>
}

export default Container
