import Script from 'next/script'
import React, { FC } from 'react'

const FacebookChat: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>

      <Script id="facebook-chat" strategy="lazyOnload">
        {`
          var chatbot = document.getElementById('fb-customer-chat');
          chatbot.setAttribute("page_id", "101772198713921");
          chatbot.setAttribute("attribution", "biz_inbox");
          window.fbAsyncInit = function() {
            FB.init({
              appId:"289126242355062",
              xfbml            : true,
              version          : 'v12.0',
            });
          };

        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
          js.target = '_top'
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      `}
      </Script>
    </div>
  )
}

export default FacebookChat
