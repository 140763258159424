import { Link } from '@components/ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import s from './SearchBar.module.css'

interface Props {
  className?: string
  id?: string
  placeholder?: string
}

const SearchBar: FC<React.PropsWithChildren<Props>> = ({
  className,
  id = 'search',
  placeholder,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [data, setData] = useState(router.query.q ?? '')
  useEffect(() => {
    router.prefetch('/q')
  }, [router])

  const goToSearchPage = (q: string) => {
    return router.push(
      {
        pathname: `/search`,
        query: q ? { q } : {},
      },
      undefined,
      { shallow: true }
    )
  }

  return (
    <div
      className={cn(
        'relative bg-base-200 text-base w-full transition-colors duration-150',
        className
      )}
    >
      <label className="hidden" htmlFor={id}>
        Search
      </label>
      <input
        id={id}
        className={s.input}
        placeholder={placeholder || t('Search')}
        defaultValue={data}
        onChange={(e) => setData(e.target.value)}
        onKeyUp={(e) => {
          e.preventDefault()

          if (e.key === 'Enter') {
            const q = e.currentTarget.value
            goToSearchPage(q)
          }
        }}
      />
      <Link href={`/search?q=${data}`}>
        <div className={s.iconContainer}>
          <svg className={s.icon} fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
        </div>
      </Link>
    </div>
  )
}

export default SearchBar
