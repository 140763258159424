import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'
const saveComment = (
  locale: string,
  currentUser: AuthUser,
  courseId,
  content
) => {
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/comment?courseId=${courseId}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      }),
      body: JSON.stringify({ content }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export default saveComment
