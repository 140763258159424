import React, { FC } from 'react'

interface Props {
  className?: string
}
const Telephone: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Telephone</title>
      <path
        d="M12 0C7.46 0 3.34 1.78 0.29 4.67C0.11 4.85 0 5.1 0 5.38C0 5.66 0.11 5.91 0.29 6.09L2.77 8.57C2.95 8.75 3.2 8.86 3.5 8.86C3.75 8.86 4 8.75 4.18 8.58C4.97 7.84 5.87 7.22 6.84 6.73C7.17 6.57 7.4 6.23 7.4 5.83V2.73C8.85 2.25 10.39 2 12 2C13.59 2 15.14 2.25 16.59 2.72V5.82C16.59 6.21 16.82 6.56 17.15 6.72C18.13 7.21 19 7.84 19.82 8.57C20 8.75 20.25 8.85 20.5 8.85C20.8 8.85 21.05 8.74 21.23 8.56L23.71 6.08C23.89 5.9 24 5.65 24 5.37C24 5.09 23.88 4.85 23.7 4.67C20.65 1.78 16.53 0 12 0ZM9 4V7C9 7 3 12 3 15V19H21V15C21 12 15 7 15 7V4H13V6H11V4H9ZM12 9C13.0609 9 14.0783 9.42143 14.8284 10.1716C15.5786 10.9217 16 11.9391 16 13C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13C8 11.9391 8.42143 10.9217 9.17157 10.1716C9.92172 9.42143 10.9391 9 12 9ZM12 10.5C11.337 10.5 10.7011 10.7634 10.2322 11.2322C9.76339 11.7011 9.5 12.337 9.5 13C9.5 13.663 9.76339 14.2989 10.2322 14.7678C10.7011 15.2366 11.337 15.5 12 15.5C12.663 15.5 13.2989 15.2366 13.7678 14.7678C14.2366 14.2989 14.5 13.663 14.5 13C14.5 12.337 14.2366 11.7011 13.7678 11.2322C13.2989 10.7634 12.663 10.5 12 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Telephone
