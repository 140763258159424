import React, { FC } from 'react'
import FiveStars from './FiveStars'

const StarRating: FC<React.PropsWithChildren<{ rating: number }>> = ({
  rating,
}) => {
  return (
    <div className="flex items-center">
      <FiveStars rating={rating} />
      <span className="ml-1 font-semibold leading-tight">
        {rating.toFixed(1)}
      </span>
    </div>
  )
}

export default StarRating
