import useDebounce from '@lib/hooks/useDebounce'
import { uploadFile } from '@lib/services/FileAssets'
import compressImage from '@lib/utils/compressImage'
import { useFormikContext } from 'formik'
import { AuthUser } from 'next-firebase-auth'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'
import SimpleBar from 'simplebar-react'
import Loading from '../Loading'

const MarkdownDynamic = dynamic(() => import('../Markdown'), {
  loading: () => <Loading />,
  ssr: false,
})

const Preview = ({ markdown, maxEditorHeight }) => {
  return (
    /* @ts-ignore */
    <SimpleBar
      autoHide={false}
      style={{ maxHeight: maxEditorHeight }}
      className="h-full"
    >
      {/* @ts-ignore */}
      <MarkdownDynamic>{markdown}</MarkdownDynamic>
    </SimpleBar>
  )
}

const defaultToolbarCommands = [
  ['header', 'bold', 'italic', 'strikethrough'],
  ['link', 'quote', 'code'],
  ['unordered-list', 'ordered-list', 'checked-list'],
]

type Props = {
  options?: {
    toolbar?: any
  }
  name: string
  value: string
  currentUser: AuthUser
  onChange: Function
  maxEditorHeight?: number
}
const defaultMessage =
  'Attach image by dragging & dropping, selecting or pasting them.'

const MdEditor: FC<React.PropsWithChildren<Props>> = ({
  options,
  value,
  currentUser,
  onChange,
  maxEditorHeight = 500,
  name,
}) => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  const [editedValue, setEditedValue] = useState(value || undefined)
  const [isUploadError, setIsUploadError] = useState(false)
  const debouncedValue = useDebounce(editedValue, 100)
  const router = useRouter()
  const locale = router.locale
  const [message, setMessage] = useState(defaultMessage)
  const { values, submitForm, setErrors, errors, touched } = useFormikContext()

  useEffect(() => {
    setEditedValue(value)
  }, [value])

  React.useEffect(() => {
    if (!debouncedValue || debouncedValue === '') {
      onChange('')
    } else if (debouncedValue !== value) {
      onChange(debouncedValue)
    }
  }, [debouncedValue])

  useEffect(() => {
    if (isUploadError) {
      const md = document.getElementsByClassName('react-mde')[0]
      md?.classList.add('error')
      setTimeout(() => {
        const md = document.getElementsByClassName('react-mde')[0]
        md?.classList.remove('error')
        setMessage(defaultMessage)
        setIsUploadError(false)
      }, 2000)
    }
  }, [isUploadError])

  const saveImage = async function* (data, file) {
    errors[name] = 'Uploading Image'
    touched[name] = false

    setErrors({ ...errors })
    let success = false
    let result = ''
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg'
    ) {
      touched[name] = false
      setErrors(delete errors[name])
      result = 'Please upload only images of .png or .jpeg type.'
    } else if (file.size > 1 * 1024 * 1024) {
      setErrors(delete errors[name])
      result = 'File size should be less than 1MB'
    } else {
      setMessage('Uploading...')
      const compressedFile = await compressImage(file, 'general')
      result = await uploadFile(locale, currentUser, 1, compressedFile, true)
        .then((r) => {
          success = true
          setMessage(defaultMessage)
          touched[name] = false
          setErrors(delete errors[name])
          return r.url
        })

        .catch((err) => {
          touched[name] = false
          setErrors(delete errors[name])

          success = false
          return `Error: could not upload file, Only images are supported`
        })
    }
    if (!success) {
      setIsUploadError(true)
      setMessage(result)
    } else {
      yield result
      return success
    }
  }
  const { t } = useTranslation()
  return (
    <div>
      <ReactMde
        toolbarCommands={options?.toolbar || defaultToolbarCommands}
        value={editedValue}
        maxEditorHeight={250}
        onChange={setEditedValue}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(
            <Preview markdown={markdown} maxEditorHeight={maxEditorHeight} />
          )
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
        paste={{
          saveImage: saveImage,
        }}
        l18n={{
          write: <span className="text-neutral px-6 py-4">{t('write')}</span>,
          preview: <span className="text-neutral">{t('preview')}</span>,
          pasteDropSelect: t(message),
          uploadingImage: t('Uploading image...'),
        }}
      />
    </div>
  )
}

export default MdEditor
