import gPhoneNumber from 'google-libphonenumber'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

const validatePhone = (phone, region?: string) => {
  let isValidPhone = true
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, region)
    if (!phoneUtil.isPossibleNumber(phoneNumber)) {
      return false
    }

    const regionCodeFromPhoneNumber =
      phoneUtil.getRegionCodeForNumber(phoneNumber)

    region = region ?? regionCodeFromPhoneNumber

    //check if the countryCode provided should be used as
    // default country code or strictly followed
    isValidPhone = phoneUtil.isValidNumberForRegion(phoneNumber, region)
  } catch (error) {
    isValidPhone = false
  }
  return isValidPhone
}
export default validatePhone
