import { Config, CustomFieldType } from '@config/constants'
import Cookies from 'js-cookie'
import { AuthUser } from 'next-firebase-auth'

const teamId = Cookies.get('team-id') ?? ''

export const registerUserToBackend = async (
  token: string,
  locale: string,
  data
) => {
  return await fetch(`${Config.API_BASE_URL}/api/account`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
    body: JSON.stringify(data),
  }).then((r) => {
    if (!r.ok) {
      throw r
    }
    return r
  })
}

export const getPublicProfile = (userId: string, locale: string) => {
  return fetch(`${Config.API_BASE_URL}/api/account/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  }).then((r) => {
    if (!r.ok) {
      throw r
    }
    return r.json()
  })
}

export const updateProfile = async (
  currentUser: AuthUser,
  user: any,
  locale: string
) => {
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/account`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(user),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const updateEmail = async (token: string, data: any, locale: string) => {
  return fetch(`${Config.API_BASE_URL}/api/account/email`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
    body: JSON.stringify(data),
  }).then((r) => {
    if (!r.ok) {
      throw r
    }
    return r
  })
}

export const getProfile = (currentUser: AuthUser, locale: string) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/account`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const checkUserDeletionStatus = (
  currentUser: AuthUser,
  locale: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/User/${currentUser.id}/InitialDelete`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const deleteUser = (
  currentUser: AuthUser,
  locale: string,
  deleteToken: string,
  email: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/User/${currentUser.id}/confirmDelete`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          deleteToken,
          emailAddressForFiles: email,
        }),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

// -------------------Custom Field User Form--------------------

export interface IUserFormResponse {
  field: string
  value?: string
  customField: boolean
  isRequired: boolean
  customFieldId: string
  customFieldType: CustomFieldType
  options?: {
    id: string
    customField: string
    option: string
    order: number
  }[]
}

export const getUserForm = (
  currentUser: AuthUser,
  locale: string,
  sessionTeamId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/CustomField/${sessionTeamId}/userform`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          'Accept-Language': locale,
          'x-team-id': teamId,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw r
      }

      return (await r.json()) as IUserFormResponse[]
    })
  })

export interface IUserFormSubmission {
  name: string
  value: string[]
  customField: boolean
  customFieldId: string
}

export const addCustomFieldSubmission = (
  currentUser: AuthUser,
  locale: string,
  data: IUserFormSubmission[],
  sessionTeamId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/CustomField/${sessionTeamId}/userform`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
          'x-team-id': teamId,
        },
        body: JSON.stringify(data),
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return await r
    })
  })
