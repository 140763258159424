const Video = ({ ...props }) => {
  return (
    <>
      <span className="sr-only">Upload</span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={22}
        width={26}
        fill="none"
        stroke="currentColor"
        aria-hidden={true}
        {...props}
        role="img"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
    </>
  )
}

export default Video
