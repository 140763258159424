import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'

/**
 * Create assignment file
 * @param currentUser the current logged in user


 */
export const createAssignments = (
  locale: string,
  currentUser: AuthUser,
  data: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Assignments`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Accept-Language': locale,
        // 'Content-Type': 'multipart/form-data',
      },
      body: data,
      // body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const updateAssignmentOrder = (
  locale: string,
  currentUser: AuthUser,
  courseIdentity: string,
  courseId: string,
  ids: string[]
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Assignments/course/${courseIdentity}/reorder`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(ids),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

/**API for to get all assignment list */

export const getAllAssignments = (
  locale: string,
  currentUser: AuthUser,
  courseIdentity: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/course/${courseIdentity}`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const getAssignmentDownload = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string,
  attachmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${assignmentId}/attachments/${attachmentId}/download`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const updateAssignments = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string,
  data: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Assignments/${assignmentId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        // 'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      // body: JSON.stringify(data),
      body: data,
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const orderAssignments = (
  locale: string,
  currentUser: AuthUser,
  courseIdentity: string,
  ids: string[]
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Assignments/course/${courseIdentity}/reorder`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(ids),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const deleteAssignments = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Assignments/${assignmentId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

/**all student assignment */
export const getAllStudentAssignment = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${assignmentId}/userassignments`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

/**single student assignment*/
export const getStudentAttachment = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string,
  userAssignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${assignmentId}/userassignments/${userAssignmentId}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

/**Download student submitted assignment */
export const getStudentDownload = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string,
  userAssignmentId: string,
  attachmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${assignmentId}/userassignment/${userAssignmentId}/download/${attachmentId}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

/**single student assignment*/
export const getStudentSubmittedAttachment = (
  locale: string,
  currentUser: AuthUser,
  assignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${assignmentId}/submitted`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })
