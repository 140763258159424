import React, { FC } from 'react'

interface Props {
  id: string
  className: string
  isOpen: boolean
}

const SideBarSwitch: FC<React.PropsWithChildren<Props>> = ({
  id,
  className,
  isOpen,
}) => {
  return (
    <div className={`flex-none ${className}`}>
      <label
        htmlFor={id}
        className=" btn btn-square  btn-ghost  btn-sm -ml-1 mr-2  swap  swap-rotate"
      >
        <input type="checkbox" checked={isOpen} onChange={() => {}} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width="32"
          height="32"
          viewBox="0 0 512 512"
          className=" swap-off fill-current inline-block w-8 h-8 stroke-current"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"
          ></path>
        </svg>
        <svg
          className=" swap-on fill-current inline-block w-8 h-8 stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
        </svg>
      </label>
    </div>
  )
}

export default SideBarSwitch
