import throttle from 'lodash.throttle'
import { FC, useEffect, useState } from 'react'
import s from './Navbar.module.css'

const NavbarRoot: FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({ children }) => {
  useEffect(() => {
    var prevScrollpos = window.pageYOffset
    window.addEventListener('scroll', (event) => {
      var currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        if (document.getElementById('navbar'))
          document.getElementById('navbar').style.top = '0'
      } else {
        if (document.getElementById('navbar'))
          document.getElementById('navbar').style.top = '-100%'
      }
      prevScrollpos = currentScrollPos
    })
  }, [])

  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])

  return (
    <div
      id={'navbar'}
      className={`${s.root}  ${hasScrolled ? 'shadow-magical' : ''}`}
    >
      {children}
    </div>
  )
}

export default NavbarRoot
