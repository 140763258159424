import { Avatar, ThemeSwitch } from '@components/common'
import analytics from '@lib/analytics'
import ClickOutside from '@lib/click-outside'
import { UserShortProfile } from '@lib/types'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import cn from 'classnames'
import { AuthUser } from 'next-firebase-auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import s from './DropdownMenu.module.css'

interface DropdownMenuProps {
  currentUser: AuthUser
}
const DropdownMenu: FC<React.PropsWithChildren<DropdownMenuProps>> = ({
  currentUser,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [display, setDisplay] = useState(false)
  const ref = useRef() as React.MutableRefObject<HTMLUListElement>
  useEffect(() => {
    if (ref.current) {
      if (display) {
        disableBodyScroll(ref.current)
      } else {
        enableBodyScroll(ref.current)
      }
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [display])

  const LINKS = [
    {
      name: t('teach'),
      href: process.env.NEXT_PUBLIC_TEACHER_APP_URL,
    },
    {
      name: t('my_learning'),
      href: '/student',
    },
    {
      name: t('my_profile'),
      href: '/u',
    },
    // {
    //   name: t('Exams'),
    //   href: '/exams',
    // },
    // {
    //   name: t('Pool'),
    //   href: '/exams/pool',
    // },
    {
      name: t('setting'),
      href: '/settings',
    },
    // {
    //   name: t('teams'),
    //   href: '/teams',
    // },
    // {
    //   name: t('SESSIONS.live_sessions'),
    //   href: '/sessions',
    // },
  ]

  return (
    currentUser.clientInitialized &&
    !!currentUser.id && (
      <ClickOutside active={display} onClick={() => setDisplay(false)}>
        <div>
          <a
            className={s.avatarButton}
            onClick={() => setDisplay(!display)}
            aria-label="Menu"
          >
            <Avatar
              user={
                {
                  displayName: currentUser?.displayName,
                  imageUrl: currentUser?.photoURL,
                } as UserShortProfile
              }
              size={'small'}
            />
          </a>
          {display && (
            <ul className={s.dropdownMenu} ref={ref}>
              {LINKS.map(({ name, href }) => (
                <li key={href}>
                  <div>
                    <Link
                      href={`${
                        name === t('my_profile')
                          ? `/u/${currentUser?.id}`
                          : href
                      }`}
                      className={cn(s.link, {
                        [s.active]:
                          router.asPath ===
                          (name === t('my_profile')
                            ? `/u/${currentUser?.id}`
                            : href),
                      })}
                      onClick={() => {
                        setDisplay(false)
                      }}
                    >
                      {name}
                    </Link>
                  </div>
                </li>
              ))}
              <li>
                <ThemeSwitch onClickCallback={setDisplay}></ThemeSwitch>
              </li>
              <li>
                <>
                  <button
                    className={cn(s.link, 'border-t w-full  mt-4')}
                    onClick={() => {
                      currentUser.signOut()
                      // SignalRConnection.getHubConnection()
                      //   .stop()
                      //   .then(() => {})
                      // setTimeout(() => {
                      //   window.location.reload()
                      // }, 600)

                      analytics.track('logout')
                    }}
                  >
                    {t('logout')}
                  </button>
                </>
              </li>
            </ul>
          )}
        </div>
      </ClickOutside>
    )
  )
}

export default DropdownMenu
