import Tippy from '@tippyjs/react'

const Tooltip = ({
  toolTipContent,
  children,
  placement = 'top',
  interactive = false,
  click = false,
}) => {
  return (
    <>
      {children ? (
        <Tippy
          // @ts-ignore
          placement={placement}
          content={toolTipContent}
          interactive={interactive}
          trigger={click ? 'click' : 'mouseenter'}
        >
          {children}
        </Tippy>
      ) : (
        ''
      )}
    </>
  )
}

export default Tooltip
