import toaster from '@lib/utils/notify'
import firebase from 'firebase/app'
import { useAuthUser } from 'next-firebase-auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ApproveEmailBanner = () => {
  const { t } = useTranslation()
  const currentUser = useAuthUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState('')
  const [showBanner, setShowBanner] = useState(true)
  const [hideBanner, setHideBanner] = useState(false)

  const resend = async () => {
    try {
      const user = firebase.auth().currentUser
      setIsSubmitting(true)
      await user.sendEmailVerification()
      setIsSubmitting(false)
      toaster.success('New confirmation email has been sent.')
    } catch (error) {
      setIsSubmitting(false)
      setMessage(error.message)
    }
  }
  return (
    <div
      className={`${showBanner ? 'opacity-100' : 'opacity-0'} ${
        hideBanner && 'hidden'
      } flex flex-col md:flex-row gap-0  alert text-xs md:text-sm rounded-none w-full h-auto shadow-lg `}
      style={{
        transition: 'all .5s linear',
        backgroundColor: '#FF9900',
        color: 'white',
      }}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>
          {t('email_banner1')} <i>({currentUser.email})</i>
          {t('email_banner2')} &nbsp;
          <u className="cursor-pointer " onClick={() => resend()}>
            {t('Resend it')}
          </u>
        </span>
      </div>
      <div className="">
        <button
          className=" btn  btn-sm  btn-ghost"
          onClick={() => {
            setTimeout(() => setHideBanner(true), 500)
            setShowBanner(false)
          }}
        >
          {t('Close')}
        </button>
        {/* <button className=" btn  btn-sm  btn-primary">Accept</button> */}
      </div>
    </div>
  )
}

export default ApproveEmailBanner
