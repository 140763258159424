import LogRocket from 'logrocket'
import { AuthUser, useAuthUser } from 'next-firebase-auth'
import * as React from 'react'

export const logRocketIdentify = (id: string, options?: any) => {
  if (id) {
    LogRocket.identify(id, {
      ...(!!options && options),
    })
  }
}

type LogRocket = {
  LogRocket: any
  identified: AuthUser | boolean
  identifyViewer: (viewer: AuthUser) => void
  initialized: boolean
  setEnabled: (enabled: boolean) => void
}

export const LogRocketContext = React.createContext<LogRocket>({
  LogRocket,
  identified: false,
  identifyViewer: (viewer: AuthUser) => {},
  initialized: false,
  setEnabled: (enabled: boolean) => {},
})

export const LogRocketProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [identified, setIdentified] = React.useState<AuthUser | boolean>(false)
  const [initialized, setInitialized] = React.useState(false)
  const [enabled, setEnabled] = React.useState(false)
  const viewer = useAuthUser()

  React.useEffect(() => {
    if (process.browser && process.env.NODE_ENV === 'production') {
      setEnabled(true)
    }
  }, [process.browser])

  const initialize = React.useCallback(() => {
    if (enabled) {
      LogRocket.init('ukdlfa/vurilo-web')
      setInitialized(true)
    }
  }, [enabled])

  const identifyViewer = React.useCallback(
    (viewer: AuthUser) => {
      if (!viewer) return

      if (!initialized) {
        initialize()
      }
      if (enabled && initialized) {
        const { id, displayName, email, emailVerified } = viewer
        logRocketIdentify(id, {
          name: displayName,
          email,
          emailVerified,
        })
        setIdentified(viewer)
      }
    },
    [viewer]
  )

  React.useEffect(() => {
    identifyViewer(viewer)
  }, [identified, initialized, viewer])

  React.useEffect(() => {
    if (!initialized) {
      initialize()
    }
  }, [initialized, initialize])

  return (
    <LogRocketContext.Provider
      value={{
        LogRocket,
        identified,
        identifyViewer,
        initialized,
        setEnabled,
      }}
    >
      {children}
    </LogRocketContext.Provider>
  )
}

export default function useLogRocket({ viewer }: { viewer: any }) {
  const logRocketContext = React.useContext(LogRocketContext)

  if (viewer) {
    logRocketContext.identifyViewer(viewer)
  }

  return logRocketContext
}
