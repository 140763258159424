import { Breadcrumb } from '@components/common'
import { LinkItem, Tree } from '@lib/types'
import React, { FC } from 'react'
import { useToggle } from 'react-use'
import { SideBar, SideBarSwitch } from '.'

interface Props {
  id: string
  menus?: LinkItem[]
  showBreadcrumbs?: boolean
  breadcrumbs: LinkItem[]
  isMobileOnly?: boolean
  isTree?: boolean
  treeMenus?: Tree
  activeTab?: string
  onAdd?: Function
  onEdit?: Function
  onDelete?: Function
  onSelect?: Function
  onActiveTab?: Function
  isAdmin?: boolean
  industryType?: number
  isCustom?: boolean
  customComponent?: JSX.Element
  nodeLimit?: number
}

const SideBarContentWrapper: FC<React.PropsWithChildren<Props>> = ({
  showBreadcrumbs = true,
  id,
  menus,
  children,
  isMobileOnly,
  breadcrumbs,
  isTree,
  treeMenus,

  ...props
}) => {
  const [isOpen, setIsOpen] = useToggle(false)
  return (
    <>
      <div className="flex flex-row lg:px-4">
        <SideBarSwitch
          id={id}
          className="lg:hidden"
          isOpen={isOpen}
        ></SideBarSwitch>
        {breadcrumbs && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
      </div>
      <div className=" divider m-0 lg:px-4"></div>
      <div
        className={
          ' drawer !h-auto mt-4  ' + (isMobileOnly ? ' drawer-mobile' : '')
        }
      >
        <input
          id={id}
          type="checkbox"
          checked={isOpen}
          onChange={setIsOpen}
          className=" drawer-toggle"
        />
        <SideBar
          treeMenus={treeMenus}
          isTree={isTree}
          id={id}
          menus={menus}
          {...props}
        ></SideBar>
        <div className="flex flex-col justify-start  drawer-content lg:pl-6 h-max">
          {children}
        </div>
      </div>
    </>
  )
}

export default SideBarContentWrapper
