import { Button, useUI } from '@components/ui'
import { useRouter } from 'next/router'
import { FC, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoginWithEmailAndPassword from './LoginWithEmailAndPassword'
import LoginWithPhone from './LoginWithPhone'
import LoginWithSocialMedia from './LoginWithSocialMedia'

interface Props {
  type: 'modal' | 'page'
}

const LoginView: FC<React.PropsWithChildren<Props>> = ({ type }) => {
  // Form State

  const { setModalView } = useUI()

  const router = useRouter()

  const handleSignUpLink = (e: MouseEvent) => {
    e.preventDefault()
    if (type === 'modal') {
      setModalView('SIGNUP_VIEW')
    } else {
      if (router.query?.redirect) {
        router.push(`/auth/register?redirect=${router.query?.redirect}`)
      } else {
        router.push('/auth/register')
      }
    }
  }

  const handleForgotPasswordLink = (e: MouseEvent) => {
    e.preventDefault()
    if (type === 'modal') {
      setModalView('FORGOT_VIEW')
    } else {
      router.push('/auth/forgot-password')
    }
  }

  const { t } = useTranslation()
  const [signInWithPhone, setSignInWithPhone] = useState(true)

  return (
    <div className="flex flex-col">
      {signInWithPhone ? (
        <LoginWithPhone type={type} />
      ) : (
        <>
          <LoginWithEmailAndPassword
            type={type}
            setSignInWithPhone={setSignInWithPhone}
            handleForgotPasswordLink={handleForgotPasswordLink}
          />
        </>
      )}
      <div className="flex flex-col w-full">
        <div className=" divider">{t('log_msg')}</div>
      </div>
      <Button
        variant="slim"
        type="submit"
        onClick={() => setSignInWithPhone(!signInWithPhone)}
      >
        {signInWithPhone ? t('Login With Email') : t('Login With Phone Number')}
      </Button>
      <div className="flex flex-col w-full">
        <div className=" divider"></div>
      </div>
      <LoginWithSocialMedia type={type} />
      <div className="pt-1 text-center text-sm">
        <span className="text-gray">{t('forget_password')} </span>
        <a
          className="font-bold hover:underline cursor-pointer mb-3"
          onClick={handleForgotPasswordLink}
        >
          {t('reset_password')}
        </a>
        <br />
        <span className="text-gray">{t('no_account')} </span>
        <a
          className="font-bold hover:underline cursor-pointer"
          onClick={handleSignUpLink}
        >
          {t('sign')}
        </a>
      </div>
    </div>
  )
}

export default LoginView
