import { Check, Cross, EditIcon } from '@components/icons'
import { Input } from '@components/ui'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from '../spinner'

type InlineInputEditorProps = {
  inputText: string
  onSave: Function
  disabled?: boolean
  onEdit?: Function
  onCloseEdit?: Function
}

const InlineInputEditor: FC<
  React.PropsWithChildren<InlineInputEditorProps>
> = ({ inputText, onSave, disabled, onEdit, onCloseEdit }) => {
  const [currentTextValue, setCurrentTextValue] = useState<string>(inputText)
  const [textValue, setTextValue] = useState<string>(currentTextValue)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const teamNameNeedsSaving = currentTextValue !== textValue

  const onMouseEnterText = () => {
    if (!inputText.localeCompare('')) {
      setTextValue('')
    }
  }
  const { t } = useTranslation()
  return (
    <>
      {!isEditing && (
        <div className="flex items-center w-72">
          <div className="truncate text-base-content">{textValue}</div>
          <div className={`mx-2 ${disabled ? '' : 'cursor-pointer'}`}>
            <EditIcon
              className="h-4 w-4 text-base-content"
              onClick={() => {
                if (!disabled) {
                  onEdit && onEdit()
                  setIsEditing(true)
                }
              }}
            ></EditIcon>
          </div>
        </div>
      )}
      {isEditing && (
        <div className="flex space-x-2">
          <Input
            type="text"
            className="truncate text-base"
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value)
            }}
            onMouseUp={onMouseEnterText}
            placeholder={t('name')}
            maxLength={100}
          />
          <div className="flex flex-row items-center">
            <button
              onClick={async () => {
                if (teamNameNeedsSaving) {
                  setLoading(true)
                  if (textValue !== '') {
                    const saveResponse = await onSave(textValue)
                    if (!saveResponse) {
                      setTextValue(inputText)
                    } else {
                      setCurrentTextValue(textValue)
                    }
                  } else {
                    setTextValue(inputText)
                  }
                }
                setLoading(false)
                setIsEditing(false)
                onCloseEdit && onCloseEdit()
              }}
              disabled={disabled}
              className="cursor-pointer w-6 h-6 hover:text-base-content hover:bg-base-200 rounded-sm"
            >
              {loading ? (
                <Spinner color="primary" />
              ) : (
                <Check>{t('save')}</Check>
              )}
            </button>
            <Cross
              className="cursor-pointer ml-4 w-6 h-6 hover:text-base-content hover:bg-base-200 rounded-sm"
              onClick={() => {
                setTextValue(currentTextValue)
                setIsEditing(false)
                onCloseEdit && onCloseEdit()
              }}
            >
              {t('cancel')}
            </Cross>
          </div>
        </div>
      )}
    </>
  )
}

export default InlineInputEditor
