import { Config } from '@config/constants'
import Cookies from 'js-cookie'
import { AuthUser } from 'next-firebase-auth'
import qs from 'query-string'

const teamId = Cookies.get('team-id') ?? ''

export const enrollCourse = (
  locale: string,
  currentUser: AuthUser,
  enroll: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/student/enroll`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(enroll),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const enrollCourseFree = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/student/enrollfree/${courseId}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
export const filterStudentCourse = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  subTeamId: string,

  completed = false
) => {
  return currentUser.getIdToken().then((token) => {
    const status = completed ? 2 : 1
    return fetch(
      subTeamId === null || !subTeamId
        ? `${Config.API_BASE_URL}/api/Student/courses/team?teamId=${teamId}&status=${status}`
        : `${Config.API_BASE_URL}/api/Student/courses/team?teamId=${teamId}&subTeamId=${subTeamId}&status=${status}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getEnrolledCourse = (
  locale: string,
  authUser: AuthUser,
  query: any,
  completed = false,
  isRecording = false
) => {
  const status = completed ? 2 : 1
  return authUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(query.search && { search: query.search }),
      ...(query.page && { page: query.page }),
      ...(query.size && { size: query.size }),
    })
    return fetch(
      isRecording
        ? `${Config.API_BASE_URL}/api/Student/Courses?isRecording=true&${queryString}`
        : `${Config.API_BASE_URL}/api/Student/Courses?status=${status}&${queryString}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          'Accept-Language': locale,
          'x-team-id': teamId,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()

      // return r.json()
    })
  })
}

export const getAllEnrolledCourse = (
  locale: string,
  authUser: AuthUser,
  query: any
) => {
  return authUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(query.search && { search: query.search }),
      ...(query.page && { page: query.page }),
      ...(query.size && { size: query.size }),
      ...(query.status && { size: query.status }),
    })
    return fetch(`${Config.API_BASE_URL}/api/Courses?${queryString}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        'Accept-Language': locale,
        'x-team-id': teamId,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

/**Student able to view assignment list */
export const fetchTeacherAssignment = (
  locale: string,
  currentUser: AuthUser,
  courseIdentity: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/course/${courseIdentity}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

/**Uploading assignments */

export const assignmentSubmit = (
  locale: string,
  currentUser: AuthUser,
  data: any
  // assignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Assignments/submit`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        // 'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: data,
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return
    })
  })

/**Fetching details of single assignments */
export const getSingleAssignment = (
  locale: string,
  currentUser: AuthUser,
  identity: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Assignments/${identity}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

// edit assignment
export const editSubmittedAssignment = (
  locale: string,
  currentUser: AuthUser,
  data: any,
  userAssignmentId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Assignments/${userAssignmentId}/updateassignment`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          // 'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: data,
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
    })
  })

//  add review
export const review = (locale: string, currentUser: AuthUser, data: any) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Assignments/Review`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
    })
  })
