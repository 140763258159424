import { Notify } from 'notiflix'

const toaster = {
  init: () => {
    Notify.init({
      useIcon: false,

      position: 'right-bottom',
      borderRadius: '0px',
    })
  },
  success: (message) => {
    Notify.success(message)
  },
  failure: (message) => {
    Notify.failure(message)
  },
}

export default toaster
