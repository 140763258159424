import cn from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import s from './Input.module.css'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onChangeHandler?: Function
  innerRef?: any
}

const Input: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    className,
    children,
    onChangeHandler,
    autoComplete,
    innerRef,
    ...rest
  } = props
  const rootClassName = cn(s.root, {}, className)

  const handleOnChange = (e: any) => {
    if (onChangeHandler) {
      onChangeHandler(e.target.value)
    }
    return null
  }
  return (
    <label className="w-full">
      <input
        ref={innerRef}
        className={rootClassName}
        onChange={handleOnChange}
        autoComplete={autoComplete}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        {...rest}
      />
    </label>
  )
}

export default Input
