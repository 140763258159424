import { Cross } from '@components/icons'
import FocusTrap from '@lib/utils/focus-trap'
import toaster from '@lib/utils/notify'
import Portal from '@reach/portal'
import { FC, useCallback, useEffect } from 'react'
import s from './Modal.module.css'

interface Props {
  className?: string
  children?: any
  open?: boolean
  classes?: string
  isTextInput?: boolean
  isOverFlowModal?: boolean
  showClose?: boolean

  onClose: () => void
  onEnter?: () => void | null
}

const Modal: FC<React.PropsWithChildren<Props>> = ({
  className,
  classes,
  children,
  open,
  onClose,
  onEnter = null,
  isTextInput = false,
  isOverFlowModal = true,
  showClose = true,
}) => {
  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose()
      }
    },
    [onClose]
  )

  const handleClose = () => {
    toaster.failure('The popup has been closed by the user')

    onClose() // Call onClose prop to close the modal
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKey)
    if (open) {
      const body = document.getElementById('main-body')
      body.style.overflow = 'hidden'
    }
    return () => {
      const body = document.getElementById('main-body')
      body.style.overflow = 'unset'
      window.removeEventListener('keydown', handleKey)
    }
  }, [open, handleKey])

  return (
    <Portal>
      {open ? (
        <div className={s.root}>
          <div
            className={
              s.modal +
              ' ' +
              classes +
              (isOverFlowModal ? ' overflow-y-auto' : '')
            }
            role="dialog"
          >
            {showClose && (
              <button
                onClick={handleClose}
                aria-label="Close panel"
                className={`hover:text-gray-500 z-10 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6`}
              >
                <Cross className="h-6 w-6" />
              </button>
            )}
            <div
              className={isOverFlowModal ? ' overflow-y-auto' : ''}
              style={{ scrollbarWidth: 'thin' }}
            >
              {isTextInput ? (
                <>{children}</>
              ) : (
                <FocusTrap>{children}</FocusTrap>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Portal>
  )
}

export default Modal
