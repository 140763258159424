import {
  Button,
  ErrorMessageBox,
  FormErrorMessageBox,
  Input,
  Logo,
} from '@components/ui'
import { WithLogin } from '@lib/hoc/LoginHoc'
import validatePhone from '@lib/validation/phoneValidation'
import { Field, Form, Formik } from 'formik'
import { useTheme } from 'next-themes'
import Link from 'next/link'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type Props = {
  onPhoneNumberSubmit: Function
  type: String
  showOtp: boolean
  loading: boolean
  onSignInWithOtp: Function
  createCaptcha: Function
  onSuccess: Function
  linkAccount?: boolean
  linkWithPhoneNumber: Function
}

const LoginWithPhone: FC<Props> = ({
  showOtp,
  loading,
  onPhoneNumberSubmit,
  onSignInWithOtp,
  type,
  linkWithPhoneNumber,
  linkAccount,
}) => {
  const { t } = useTranslation()
  const { resolvedTheme: theme } = useTheme()

  return (
    <Formik
      initialValues={{
        phone: '',
        otp: '',
        showOtp: showOtp,
        countryInitial: 'np',
      }}
      validate={(values) => {
        const errors: any = {}

        if (!values.phone) {
          errors.phone = t('phone_req')
        } else if (!validatePhone(values.phone, values.countryInitial)) {
          errors.phone =
            'Phone number must be a valid phone number for region ' +
            values.countryInitial?.toUpperCase()
        }
        if (showOtp) {
          if (!values.otp) {
            errors.otp = t('otp_req')
          } else if (values.otp.length !== 6) {
            errors.otp = t('otp_length')
          }
        }
        return errors
      }}
      onSubmit={(values) => {
        if (showOtp) {
          onSignInWithOtp(values.otp, false)
        } else {
          linkAccount
            ? linkWithPhoneNumber(values.phone)
            : onPhoneNumberSubmit(values.phone)
        }
      }}
    >
      {({ handleSubmit, values, errors, setValues }) => (
        <Form
          onSubmit={handleSubmit}
          className="sm:w-80 flex flex-col sm:justify-between"
        >
          <Link href={'/'}>
            <div className="flex justify-center sm:pb-5 ">
              <Logo width="64px" height="64px" className="cursor-pointer" />
            </div>
          </Link>

          <div className=" form-control pt-2 pb-4">
            <div className="">
              <PhoneInput
                onEnterKeyPress={() => handleSubmit()}
                countryCodeEditable={false}
                disabled={showOtp}
                country={'np'}
                autoFormat={false}
                enableSearch={true}
                inputProps={{
                  autoFocus: true,
                }}
                value={values.phone}
                isValid={(value, country: any) => {
                  return validatePhone(value, country.iso2)
                }}
                inputStyle={{
                  width: '320px',
                  color: theme === 'dark' ? 'white' : 'black',
                  backgroundColor: theme === 'dark' ? '#465A7E66' : '',
                }}
                containerClass="w-full rounded-sm  text-black"
                dropdownClass={
                  theme === 'dark' ? 'text-currentColor' : '' + 'w-full'
                }
                dropdownStyle={{
                  width: '320px',
                }}
                searchStyle={{
                  backgroundColor: 'white',
                  width: 'auto',
                }}
                onChange={(phone, country: any) => {
                  setValues({
                    ...values,
                    phone,
                    countryInitial: country.countryCode,
                  })
                }}
              />

              <span className="flex tracking-tight">
                <ErrorMessageBox
                  message={errors.phone}
                  hasError={!!errors.phone}
                ></ErrorMessageBox>
              </span>
            </div>
          </div>
          <div className={` form-control pt-3 pb-4 ${!showOtp && 'hidden'}`}>
            <div className="relative">
              <Field
                as={Input}
                id="otp"
                name="otp"
                placeholder={t('OTP')}
                focus
              />
              <span className="absolute flex tracking-tight">
                <FormErrorMessageBox name={'otp'}></FormErrorMessageBox>
              </span>
            </div>
          </div>
          <span className="py-3" />

          <Button
            variant="slim"
            onClick={() => handleSubmit()}
            id="signIn"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            {showOtp ? t('log') : t('Next')}
          </Button>
          <div className="h-4"></div>
        </Form>
      )}
    </Formik>
  )
}

export default WithLogin(LoginWithPhone)
