import { Cross } from '@components/icons'
import FocusTrap from '@lib/utils/focus-trap'
import Portal from '@reach/portal'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, LoadingDots } from '..'
import s from './ConfirmDialog.module.css'

interface Props {
  className?: string
  children?: any
  open?: boolean
  loading: boolean
  message: string | React.ReactNode
  title: string
  onClose: () => void | null
  onConfirm?: () => void | Promise<void>
}

const ConfirmDialog: FC<React.PropsWithChildren<Props>> = ({
  title,
  message,
  onClose,
  onConfirm = null,
  open,
  loading,
}) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current)
        window.addEventListener('keydown', handleKey)
      } else {
        enableBodyScroll(ref.current)
      }
    }
    return () => {
      window.removeEventListener('keydown', handleKey)
      clearAllBodyScrollLocks()
    }
  }, [open, handleKey])
  const { t } = useTranslation()
  return (
    <Portal>
      {open ? (
        <div className={s.root}>
          <div className={s.modal} role="dialog" ref={ref}>
            <div className="flex justify-center relative mt-5 mb-6">
              <h3 className="text-xl font-medium">{t('sure')}</h3>
            </div>

            <button
              onClick={() => onClose()}
              aria-label="Close panel"
              className="hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6"
            >
              <Cross className="h-6 w-6" />
            </button>
            <FocusTrap focusFirst>
              <div>
                <div className="flex flex-col space-y-2">
                  <p className="flex justify-center">{message}</p>

                  <div className="flex flex-row justify-center space-x-2 mt-5">
                    <Button
                      disabled={loading}
                      onClick={onConfirm}
                      variant="slim"
                    >
                      {t('confirm')} {loading && <LoadingDots />}
                    </Button>
                    <Button
                      onClick={onClose}
                      disabled={loading}
                      variant="outline"
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                </div>
              </div>
            </FocusTrap>
          </div>
        </div>
      ) : null}
    </Portal>
  )
}

export default ConfirmDialog
