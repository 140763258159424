import { vuriloThumbnailImageLoader } from '@lib/utils/image-loader'
import Image, { ImageProps } from 'next/image'
import React, { FC } from 'react'

const CourseThumbnailImage: FC<React.PropsWithChildren<ImageProps>> = ({
  ...props
}) => {
  return <Image {...props} loader={vuriloThumbnailImageLoader}></Image>
}

export default CourseThumbnailImage
