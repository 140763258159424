import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'
import qs from 'query-string'

export const getEnrolledAssessment = (
  currentUser: AuthUser,
  locale: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/QuestionSet`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getOneAssessment = (
  currentUser: AuthUser,
  id: string,
  locale: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/QuestionSet/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getAssessmentResults = (
  currentUser: AuthUser,
  id: string,
  locale: string,
  query: any
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(query.search && { search: query.search }),
      ...(query.page && { page: query.page }),
      ...(query.size && { size: query.size }),
    })
    return fetch(
      `${Config.API_BASE_URL}/api/QuestionSet/${id}/results?${queryString}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getOneResults = (
  currentUser: AuthUser,
  id: string,
  locale: string,
  userId: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/QuestionSet/${id}/results/${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const getAssessmentResultDetails = (
  currentUser: AuthUser,
  id: string,
  locale: string,
  questionSetSubmissionId: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/QuestionSet/${id}/result/${questionSetSubmissionId}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return r.json()
    })
  })
}

export const getOneAssessmentQuestions = (
  currentUser: AuthUser,
  id: string,
  locale: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/QuestionSet/${id}/questions`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const submitQuestion = (
  currentUser: AuthUser,
  submissionId: string,
  id: string,
  locale: string,
  data: any
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/QuestionSet/${id}/submission/${submissionId}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(data),
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r
    })
  })
}

export const startExamService = (
  currentUser: AuthUser,
  id: string,
  locale: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/QuestionSet/${id}/startExam`, {
      method: 'POST',
      body: '{}',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const addTags = (
  currentUser: AuthUser,
  locale: string,
  name: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Tag`, {
      method: 'Post',
      body: JSON.stringify({ name }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}
