const AppStoreIcon = ({ ...props }) => {
  return (
    <>
      <span className="sr-only">Download on the App store</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 178 54"
        aria-hidden="true"
        {...props}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-1124 -536)">
            <g transform="translate(1125 537)">
              <path
                stroke="currentColor"
                strokeWidth="1.035"
                d="M7.124 0h161.752C172.794 0 176 3.198 176 7.106v37.788c0 3.908-3.206 7.106-7.124 7.106H7.124C3.206 52 0 48.802 0 44.894V7.106C0 3.198 3.206 0 7.124 0"
              ></path>
              <g fill="currentColor" transform="translate(36.206 13.933)">
                <path d="M15.32 12.339c-.026-2.861 2.331-4.254 2.44-4.32-1.336-1.955-3.406-2.222-4.133-2.243-1.738-.184-3.424 1.045-4.31 1.045-.903 0-2.266-1.027-3.735-.996-1.89.03-3.661 1.13-4.631 2.837-2.003 3.482-.509 8.6 1.409 11.414.96 1.38 2.08 2.92 3.549 2.864 1.436-.059 1.972-.92 3.705-.92 1.718 0 2.22.92 3.718.886 1.54-.025 2.511-1.385 3.438-2.777 1.109-1.58 1.554-3.136 1.572-3.215-.037-.013-2.993-1.146-3.022-4.575"></path>
                <path d="M12.491 3.925c.772-.97 1.3-2.29 1.154-3.63-1.118.05-2.515.776-3.32 1.726-.713.836-1.349 2.207-1.185 3.495 1.256.095 2.546-.636 3.351-1.591"></path>
                <path d="M29.079 17.84h3.884l-1.915-5.665h-.053l-1.916 5.665zm4.392 1.611h-4.9l-1.177 3.49h-2.076l4.643-12.914h2.156l4.641 12.914h-2.11l-1.177-3.49z"></path>
                <path d="M44.806 18.234c0-1.906-.982-3.16-2.478-3.16-1.47 0-2.459 1.28-2.459 3.16 0 1.897.99 3.168 2.46 3.168 1.495 0 2.477-1.245 2.477-3.168zm1.977 0c0 2.926-1.559 4.806-3.912 4.806-1.336 0-2.396-.6-2.949-1.647h-.044v4.663h-1.925v-12.53h1.863v1.565h.035c.535-1.01 1.676-1.664 2.985-1.664 2.379 0 3.947 1.89 3.947 4.807z"></path>
                <path d="M55.121 18.234c0-1.906-.98-3.16-2.476-3.16-1.47 0-2.459 1.28-2.459 3.16 0 1.897.989 3.168 2.459 3.168 1.496 0 2.476-1.245 2.476-3.168zm1.978 0c0 2.926-1.558 4.806-3.911 4.806-1.336 0-2.396-.6-2.949-1.647h-.045v4.663H48.27v-12.53h1.862v1.565h.036c.535-1.01 1.675-1.664 2.984-1.664 2.38 0 3.947 1.89 3.947 4.807z"></path>
                <path d="M63.918 19.343c.142 1.281 1.381 2.122 3.073 2.122 1.622 0 2.79-.84 2.79-1.996 0-1.003-.705-1.603-2.37-2.014l-1.667-.403c-2.36-.573-3.457-1.682-3.457-3.482 0-2.228 1.934-3.759 4.678-3.759 2.717 0 4.58 1.531 4.641 3.759h-1.942c-.116-1.289-1.177-2.067-2.726-2.067-1.55 0-2.61.788-2.61 1.933 0 .913.676 1.45 2.334 1.862l1.416.349c2.638.627 3.734 1.69 3.734 3.58 0 2.416-1.916 3.93-4.963 3.93-2.85 0-4.777-1.478-4.9-3.814h1.97"></path>
                <path d="M75.964 11.298v2.227h1.783v1.531h-1.783v5.19c0 .808.357 1.183 1.14 1.183.197 0 .51-.027.633-.045v1.522c-.213.054-.64.09-1.068.09-1.898 0-2.638-.717-2.638-2.542v-5.398H72.67v-1.53h1.362v-2.228h1.933"></path>
                <path d="M85.71 18.234c0-2.032-.926-3.232-2.485-3.232-1.56 0-2.485 1.208-2.485 3.232 0 2.04.925 3.23 2.485 3.23 1.559 0 2.485-1.19 2.485-3.23zm-6.931 0c0-2.963 1.737-4.824 4.446-4.824 2.717 0 4.446 1.861 4.446 4.824 0 2.97-1.72 4.824-4.446 4.824s-4.446-1.853-4.446-4.824z"></path>
                <path d="M89.257 13.525h1.836v1.603h.044c.294-1.065 1.15-1.7 2.254-1.7.277 0 .507.036.66.071v1.808c-.153-.063-.491-.117-.865-.117-1.238 0-2.004.843-2.004 2.167v5.584h-1.925v-9.416"></path>
                <path d="M96.417 17.366h4.686c-.045-1.442-.962-2.39-2.298-2.39-1.328 0-2.29.967-2.388 2.39zm6.504 2.81c-.259 1.71-1.915 2.882-4.036 2.882-2.727 0-4.42-1.835-4.42-4.779 0-2.954 1.703-4.87 4.34-4.87 2.593 0 4.224 1.79 4.224 4.645v.663h-6.621v.116c0 1.611 1.007 2.667 2.522 2.667 1.068 0 1.907-.51 2.165-1.324h1.826z"></path>
                <path d="M27.566 5.634h1.165c1.292 0 2.036-.808 2.036-2.232 0-1.402-.757-2.22-2.036-2.22h-1.165v4.452zM28.837.306c1.833 0 2.908 1.13 2.908 3.083 0 1.983-1.067 3.122-2.908 3.122h-2.23V.306h2.23z"></path>
                <path d="M36.28 4.167c0-1.015-.455-1.608-1.252-1.608-.8 0-1.25.593-1.25 1.608 0 1.024.45 1.613 1.25 1.613.797 0 1.251-.594 1.251-1.613zm-3.452 0c0-1.509.839-2.43 2.2-2.43 1.358 0 2.197.921 2.197 2.43 0 1.519-.835 2.435-2.197 2.435-1.365 0-2.2-.916-2.2-2.435z"></path>
                <path d="M43.0695894 6.51144L42.1150482 6.51144 41.1522247 3.06176 41.0787188 3.06176 40.1200365 6.51144 39.1748129 6.51144 37.8889776 1.82832 38.8228129 1.82832 39.65726 5.40176 39.7266247 5.40176 40.6853071 1.82832 41.5673776 1.82832 42.52606 5.40176 42.5985306 5.40176 43.4298718 1.82832 44.3502482 1.82832 43.0695894 6.51144"></path>
                <path d="M45.431 1.828h.885v.744h.069c.226-.52.69-.834 1.391-.834 1.04 0 1.614.627 1.614 1.742V6.51h-.92v-2.8c0-.752-.325-1.127-1.007-1.127-.68 0-1.113.457-1.113 1.187v2.74h-.919V1.828"></path>
                <path d="M50.8560365 6.51144L51.7764129 6.51144 51.7764129 0 50.8560365 0z"></path>
                <path d="M56.506 4.167c0-1.015-.455-1.608-1.251-1.608-.8 0-1.25.593-1.25 1.608 0 1.024.45 1.613 1.25 1.613.796 0 1.25-.594 1.25-1.613zm-3.451 0c0-1.509.839-2.43 2.2-2.43 1.357 0 2.197.921 2.197 2.43 0 1.519-.836 2.435-2.197 2.435-1.366 0-2.2-.916-2.2-2.435z"></path>
                <path d="M61.417 4.787v-.392l-1.138.073c-.642.044-.933.263-.933.676 0 .421.364.666.864.666.694 0 1.207-.443 1.207-1.023zm-2.996.4c0-.843.624-1.33 1.734-1.398l1.262-.073V3.31c0-.494-.325-.773-.953-.773-.514 0-.87.189-.973.52h-.89c.094-.804.847-1.32 1.905-1.32 1.169 0 1.827.584 1.827 1.573v3.2h-.885v-.658h-.073c-.278.47-.787.735-1.4.735-.899 0-1.554-.546-1.554-1.402z"></path>
                <path d="M64.493 4.167c0 .993.466 1.591 1.245 1.591.776 0 1.255-.606 1.255-1.587 0-.975-.484-1.59-1.255-1.59-.774 0-1.245.601-1.245 1.586zm-.95 0c0-1.48.757-2.417 1.934-2.417.64 0 1.178.306 1.43.822h.07V0h.919v6.511h-.881v-.74h-.074c-.277.513-.822.817-1.464.817-1.185 0-1.934-.937-1.934-2.42z"></path>
                <path d="M75.153 4.167c0-1.015-.454-1.608-1.25-1.608-.8 0-1.25.593-1.25 1.608 0 1.024.45 1.613 1.25 1.613.796 0 1.25-.594 1.25-1.613zm-3.45 0c0-1.509.84-2.43 2.2-2.43 1.357 0 2.197.921 2.197 2.43 0 1.519-.836 2.435-2.197 2.435-1.365 0-2.2-.916-2.2-2.435z"></path>
                <path d="M77.335 1.828h.885v.744h.068c.227-.52.69-.834 1.392-.834 1.04 0 1.614.627 1.614 1.742V6.51h-.92v-2.8c0-.752-.326-1.127-1.007-1.127-.68 0-1.113.457-1.113 1.187v2.74h-.92V1.828"></path>
                <path d="M86.492.662V1.85h1.01v.78h-1.01v2.407c0 .49.202.706.66.706.141 0 .222-.01.35-.022v.77a2.95 2.95 0 01-.5.047c-1.024 0-1.43-.361-1.43-1.264V2.628h-.74V1.85h.74V.662h.92"></path>
                <path d="M88.76 0h.911v2.58h.073c.225-.524.718-.838 1.421-.838.993 0 1.606.632 1.606 1.746v3.023h-.921V3.716c0-.748-.347-1.127-.997-1.127-.754 0-1.174.478-1.174 1.187v2.735h-.92V0"></path>
                <path d="M94.898 3.738h2.356c-.022-.736-.467-1.214-1.148-1.214-.68 0-1.156.483-1.208 1.214zm3.237 1.509c-.21.84-.955 1.355-2.02 1.355-1.336 0-2.153-.92-2.153-2.418 0-1.497.835-2.446 2.149-2.446 1.297 0 2.08.89 2.08 2.36v.323h-3.293v.052c.032.82.507 1.342 1.242 1.342.558 0 .939-.203 1.11-.568h.885z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default AppStoreIcon
