import { FC } from 'react'
import s from './LoadingDots.module.css'
const LoadingDots: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <span className={s.root}>
      <span />
      <span />
      <span />
    </span>
  )
}

export default LoadingDots
