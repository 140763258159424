import { LoadingDots } from '@components/ui'

const Loading = () => {
  return (
    <div className="w-80 h-80 flex items-center text-center justify-center p-3 text-base-content ">
      <LoadingDots />
    </div>
  )
}

export default Loading
