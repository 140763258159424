import { registerUserToBackend } from '@lib/services'
import firebase from 'firebase/app'
import { NextRouter } from 'next/router'

export const createAccountToBackEnd = async (locale) => {
  const currentUser = firebase.auth().currentUser
  const token = await currentUser.getIdToken()
  const userDetails = {
    firstName: currentUser?.displayName?.split(' ')[0],
    lastName:
      currentUser?.displayName?.split(' ')[
        currentUser?.displayName?.split(' ').length - 1
      ],
  }
  if (currentUser.photoURL && currentUser.photoURL !== 'null') {
    userDetails['imageUrl'] = currentUser?.photoURL
  }
  await registerUserToBackend(token, locale as string, userDetails)
}

export const redirect = (router: NextRouter) => {
  if (router.query?.redirect) {
    const url = router.query.redirect as string
    location.replace(url)
  } else {
    router.push('/')
  }
}
