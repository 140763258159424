import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'

interface ITeams {
  id: string
  slug: string
  name: string
  phoneNumber: string
  contactPersonEmail: string
  logoUrl: string
  isActive: true
  role: string
  dateJoined: string
}
export const getMyTeams = (
  locale: string,
  currentUser: AuthUser
): Promise<ITeams[]> =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams?isTeacher=false`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const createNewTeam = (
  locale: string,
  currentUser: AuthUser,
  data: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const updateTeam = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  data: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams/${teamId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({
        contactPersonEmail: data.contactPersonEmail,
        name: data.name,
        phoneNumber: data.phoneNumber,
        teamCategory: Number(data.teamCategory),
      }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
export const getTeamDetail = (
  locale: string,
  currentUser: AuthUser,
  id: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const getSubTeamDetail = (
  locale: string,
  currentUser: AuthUser,
  id: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/subTeams/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const getTeamMembers = (
  locale: string,
  currentUser: AuthUser,
  id: string,
  search: string,
  pageParam: number,
  isActive: boolean
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/teams/${id}/users?search=${search}&page=${pageParam}&isActive=${isActive}&size=50`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const inviteMember = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  email: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/teams/${teamId}/invite?email=${email}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getPendingTeamMembers = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  pageParam: number,
  search: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/teams/${teamId}/pendingInvitation?page=${pageParam}&search=${search}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const deletePendingTeamMember = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  id: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams/${teamId}/invite/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const deleteActiveTeamMember = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  id: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/teams/${teamId}/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const joinTeam = (locale: string, currentUser: AuthUser, code: string) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/teams/verify?token=${encodeURIComponent(
        code
      )}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getInvitationDetail = (
  locale: string,
  currentUser: AuthUser,
  token: string
) =>
  currentUser.getIdToken().then((authToken) => {
    return fetch(`${Config.API_BASE_URL}/api/teams/invitation/${token}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const addSubTeam = async (
  locale: string,
  currentUser: AuthUser,
  name: string,
  description: string,
  parentId: string,
  teamId: string
) => {
  return currentUser.getIdToken().then((authToken) => {
    return fetch(`${Config.API_BASE_URL}/api/SubTeams`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({
        name,
        description,
        parentId: parentId,
        teamId,
      }),
    }).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return await r.json()
    })
  })
}

export const editSubTeam = async (
  locale: string,
  currentUser: AuthUser,
  name: string,
  description: string,
  subTeamId: string,
  teamId: string
) => {
  const authToken = await currentUser.getIdToken()
  const r = await fetch(`${Config.API_BASE_URL}/api/SubTeams/${subTeamId}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
    body: JSON.stringify({
      name,
      description,
      teamId,
    }),
  })
  if (!r.ok) {
    throw r
  }
  return await r.json()
}

export const deleteSubTeam = async (
  locale: string,
  currentUser: AuthUser,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(`${Config.API_BASE_URL}​/api/SubTeams/${subTeamId}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  })
  if (!r.ok) {
    throw r
  }
  return r
}

export const getSubTeamMember = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  subTeamId: string,
  search: string,
  page: Number
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subteam/${subTeamId}/member?page=${page}&search=${search}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
  const y = await r.json()
  return y
}

export const getTeams = (locale: string, teamId, currentUser: AuthUser) => {
  return currentUser.getIdToken().then((token) =>
    fetch(`${Config.API_BASE_URL}/api/SubTeams/Team/${teamId}`, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  )
}

export const getAllTeamMember = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subTeam/${subTeamId}/teamuser`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
  const y = await r.json()
  return y
}

export const addTeamMember = async (
  locale: string,
  ids: string[],
  currentUser: AuthUser,
  teamId: string,
  subTeam: string
) => {
  return currentUser.getIdToken().then((token) =>
    fetch(
      `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subTeam/${subTeam}/member`,
      {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify({ users: ids }),
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return await r.json()
    })
  )
}

// assign and delete teacher end

export const onDeleteMember = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  userId: string,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subteam/${subTeamId}/member/${userId}`,
    {
      method: 'delete',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
}

export const getMySubTeamsCourse = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    subTeamId
      ? `${Config.API_BASE_URL}/api/Courses/team/${teamId}/subteam?subTeamIdentity=${subTeamId}`
      : `${Config.API_BASE_URL}/api/Courses/team/${teamId}/subteam`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
  return r.json()
}

export const getAllTeamsAndSubTeams = (locale: string, currentUser: AuthUser) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Teams/subteams`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const assignTeacherForSubTeams = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  userId: string,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subteam/${subTeamId}/assignTeacher/${userId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
}

export const removeTeacherForSubTeam = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  userId: string,
  subTeamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/SubTeams/team/${teamId}/subteam/${subTeamId}/removeteacher/${userId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
}

export const setTeamRoles = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  userId: string,
  role: number
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/Teams/${teamId}/assignroles/${userId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({ Role: role }),
    }
  )
  if (!r.ok) {
    throw r
  }
}

export const setAsActive = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  userId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/Teams/${teamId}/activatemember/${userId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!r.ok) {
    throw r
  }
}

export const addBulkInvitation = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  emails
) => {
  return currentUser.getIdToken().then((token) =>
    fetch(`${Config.API_BASE_URL}/api/Teams/${teamId}/invite`, {
      method: 'post',
      body: JSON.stringify({ email: emails }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw r
      }
      return await r.json()
    })
  )
}

export const updateActiveTeam = async (
  locale: string,
  currentUser: AuthUser,
  teamId: string
) => {
  const token = await currentUser.getIdToken()
  const r = await fetch(
    `${Config.API_BASE_URL}/api/teams/active?Team=${teamId}&IsTeacher=false`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )

  if (!r.ok) {
    throw r
  }
}
