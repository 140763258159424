import { ThemeSwitch } from '@components/common'
import analytics from '@lib/analytics'
import { useTranslation } from 'react-i18next'

const BottomFooter = () => {
  const { t } = useTranslation()
  return (
    <div className="py-3 flex flex-col text-xs md:flex-row justify-between items-center bg-base-100 text-base-content">
      <div className="flex items-center">
        <span>
          &copy; <span>{new Date().getFullYear()} Vurilo</span>{' '}
        </span>
      </div>
      <div className="flex flex-col md:flex-row items-center">
        <span className="text-base-content pt-3 pl-4 md:pt-0">
          <a
            href="https://status.vurilo.com/"
            target="_blank"
            rel="noreferrer"
            aria-label="Vurilo's status"
            onClick={() => {
              analytics.track(`status_link_clicked`, {
                location: 'footer',
              })
            }}
          >
            {t('status')}
          </a>
        </span>
        <ThemeSwitch onClickCallback={() => {}}></ThemeSwitch>
      </div>
    </div>
  )
}

export default BottomFooter
