import { AuthUser } from 'next-firebase-auth'
import { ThemeProvider } from 'next-themes'
import React, { FC, useCallback, useMemo } from 'react'

export interface State {
  authenticated?: boolean
  authToken?: any
  loading: boolean
  displayDropdown: boolean
  displayModal: boolean
  modalView: string
  currentUserProfile: any
  setViewerEmail: (newEmail: string) => void
}

const initialState = {
  authenticated: false,
  authToken: null,
  loading: false,
  displayDropdown: false,
  displayModal: false,
  modalView: '',
  currentUserProfile: null,
  setViewerEmail: (_) => {},
}

type Action =
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'LOGIN'
      user: AuthUser
    }
  | {
      type: 'LOGOUT'
    }
  | {
      type: 'SET_VIEWER_EMAIL'
      newEmail: string
    }
  | {
      type: 'SET_USER_PROFILE'
      value: any
    }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'REMOVE_TEAM_MEMBER'
  | 'CREATE_TEAM_MODAL'
  | 'CREATE_NEW_COURSE_MODAL'
  | 'INVITE_TEAM_MEMBER_MODAL'
  | 'DELETE_COURSE_CONFIRM_MODAL'
  | 'CREATE_NEW_COURSE_SECTION_MODAL'
  | 'UPDATE_LESSON_THUMBNAIL_MODAL'
  | 'DELETE_SECTION_CONFIRM_MODAL'
  | 'DELETE_LESSON_CONFIRM_MODAL'
  | 'ERROR_MODEL_LIVE_SESSION'
  | 'ERROR_MODEL_LIVE_SESSION_MODERATOR'
  | 'INVITE_LIVE_SESSION_MODERATOR_MODAL'
  | 'REMOVE_LIVE_SESSION_MODERATOR_MODAL'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      if (state.modalView) {
        return {
          ...state,
          displayModal: true,
          displaySidebar: false,
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }

    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }

    case 'LOGIN': {
      return {
        ...state,
        currentAuthUser: action.user,
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        currentAuthUser: null,
      }
    }
  }
}

export const UIProvider: FC<React.PropsWithChildren<unknown>> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openDropdown = () => dispatch({ type: 'OPEN_DROPDOWN' })
  const closeDropdown = () => dispatch({ type: 'CLOSE_DROPDOWN' })

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )
  const setModalView = (view: MODAL_VIEWS) =>
    dispatch({ type: 'SET_MODAL_VIEW', view })

  const handleLogin = (user: AuthUser) => dispatch({ type: 'LOGIN', user })

  const handleLogout = () => dispatch({ type: 'LOGOUT' })

  const setCurrentUserProfile = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_PROFILE', value }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      handleLogin,
      handleLogout,
      setCurrentUserProfile,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
