import { Config } from '@config/constants'
import { CoursePageResource } from '@lib/types'
import Cookies from 'js-cookie'
import { AuthUser } from 'next-firebase-auth'

const teamId = Cookies.get('team-id') ?? ''

export const createCourse = (
  locale: string,
  currentUser: AuthUser,
  courseDetail: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(courseDetail),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const filterTeachingCourse = (
  locale: string,
  currentUser: AuthUser,
  teamId: string,
  subTeamId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      subTeamId
        ? `${Config.API_BASE_URL}/api/Courses/teaching/team/${teamId}/subteam?subTeamIdentity=${subTeamId}`
        : `${Config.API_BASE_URL}/api/Courses/teaching/team/${teamId}/subteam`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const updateCourse = (
  locale: string,
  currentUser: AuthUser,
  id: string,
  courseDetail: any
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(courseDetail),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const getCourseInfo = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses/${courseId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const getCourseDetailForPublic = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
): Promise<CoursePageResource> =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses/details/${courseId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const deleteCourse = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses/${courseId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getAllSections = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/section?courseId=${courseId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const getSingleSection = (
  locale: string,
  currentUser: AuthUser,
  sectionId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/section/${sectionId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const createSection = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  data: { name: string; description: string; order: number }
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/section?courseId=${courseId}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const updateSection = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  sectionId: string,
  data: { name: string; order: number }
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/section/${sectionId}?courseId=${courseId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(data),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const deleteSection = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  sectionId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/section/${sectionId}?courseId=${courseId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getAllLesson = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/lesson?courseId=${courseId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const createLesson = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  sectionId: string,
  data: LessonRequest
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/lesson?courseId=${courseId}&sectionId=${sectionId}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(data),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const updateLesson = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  lessonId: string,
  data: LessonRequest
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/lesson/${lessonId}?courseId=${courseId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(data),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const deleteLesson = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  lessonId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/lesson/${lessonId}?courseId=${courseId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
export const publishCourse = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/courses/AddReview/${courseId}`, {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

// publish updated course
export const updatePublished = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Courses/UpdatedCourseAddReview/${courseId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

// change reviewed Course to draft
export const changeToDraft = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Courses/AddDraft/${courseId}`, {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

/**
 * Uploads the video lesson file
 * @param currentUser the current logged in user
 * @param file the video file
 * @returns response of API which contains the cloudfront URL, duration and thumbnail URL.
 */
export const uploadLessonFile = (
  locale: string,
  currentUser: AuthUser,
  file: any,
  lessonId: string
  // image: any
) =>
  currentUser.getIdToken().then((token) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('lessonId', lessonId)
    // formData.append('thumbnail', image)
    return fetch(`${Config.VIDEO_SERVICE_URL}/api/Media/video`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Accept-Language': locale,
      },
      body: formData,
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

type LessonRequest = {
  name?: string
  order: number
  isFree?: boolean
  thumbnailUrl?: string
  sectionId: string
  videoUrl?: string
  duration?: number
  description?: string
  instructorId?: string
}

export const getLessonResource = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  lessonId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Courses/${courseId}/lesson`
    if (lessonId) {
      url += `?lessonIdentity=${lessonId}`
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

/**
 * Update the section order
 * @param currentUser the current logged in user
 * @param courseId the course id
 * @param ids the section ordered ids
 */
export const updateSectionOrder = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  ids: string[]
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/courses/${courseId}/sections/reorder`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(ids),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

/**
 * Update the lesson order
 * @param currentUser the current logged in user
 * @param courseId the course id
 * @param sectionId the section id
 * @param ids the lesson ordered ids
 */
export const updateLessonOrder = (
  locale: string,
  currentUser: AuthUser,
  courseId: string,
  sectionId: string,
  ids: string[]
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Courses/${courseId}/sections/${sectionId}/lessons/reorder`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
        body: JSON.stringify(ids),
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getVideoUrl = (
  locale: string,
  currentUser: AuthUser,
  lessonId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Lesson/video/${lessonId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

//Update published course
export const updatePublishedCourse = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Courses/PublishUpdatedCourse/${courseId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const rejectUpdatedCourse = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Courses/RejectUpdatedCourse`, {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const updateCourseAddReview = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Courses/UpdatedCourseAddReview/${courseId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const updateCourses = (
  locale: string,
  currentUser: AuthUser,
  courseId: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(
      `${Config.API_BASE_URL}/api/Courses/UpdateCourse/${courseId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const fetchTrendingCourse = (locale: string, currentUser: AuthUser) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/Courses/Trending`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        'x-team-id': teamId,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
