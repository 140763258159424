import { getMyTeams } from '@lib/services'
import { getAssessmentResults } from '@lib/services/assessment'
import { fetchCategories, fetchSubCategories } from '@lib/services/lookup'
import {
  getQuestions,
  getQuestionSetInvigilator,
  getQuestionSetParticipants,
  getQuestionSetQuestionList,
  getQuestionsList,
  QuestionsFilterType,
} from '@lib/services/MCQ'
import {
  getPool,
  getPoolInvigilator,
  getSinglePool,
  getTags,
} from '@lib/services/pool'
import { AuthUser } from 'next-firebase-auth'
import { useQuery } from 'react-query'

export const useCategories = (locale: string) => {
  return useQuery(
    ['categories', fetchCategories],
    () => fetchCategories(locale),
    {
      staleTime: 60 * 60 * 1000, // 1hr
      cacheTime: 60 * 60 * 1000,
    }
  )
}

export const useSubCategories = (categoryId, locale: string) => {
  return useQuery(
    ['sub-categories', categoryId],
    () => fetchSubCategories(categoryId, locale),
    {
      staleTime: 60 * 60 * 1000, // 1hr
      cacheTime: 60 * 60 * 1000,
      enabled: !!categoryId,
    }
  )
}

export const useMyTeams = (currentUser, locale: string) => {
  return useQuery(
    ['my-teams', currentUser.id],
    () => getMyTeams(locale, currentUser),
    {
      enabled: currentUser.clientInitialized,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 2,
    }
  )
}

export const useQuestionPool = (
  questionPoolId: string,
  currentUser,
  locale
) => {
  return useQuery(
    ['pool-data', questionPoolId, currentUser.id],
    () => getSinglePool(currentUser, locale, questionPoolId),
    {
      enabled: currentUser.clientInitialized,
    }
  )
}

export const usePoolInvigilatorList = (
  currentUser: AuthUser,
  locale: string,
  questionPoolId: string,
  search: string
) => {
  return useQuery(
    ['invigilator-data', currentUser.id, questionPoolId, search],
    () => getPoolInvigilator(currentUser, locale, questionPoolId, search),
    {
      enabled: currentUser.clientInitialized,
    }
  )
}

export const useQuestionSetInvigilatorList = (
  currentUser: AuthUser,
  locale: string,
  questionSetId: string,
  search: string
) => {
  return useQuery(
    ['questionset-invigilator', currentUser.id, questionSetId, search],
    () => getQuestionSetInvigilator(currentUser, locale, questionSetId, search),
    { enabled: currentUser.clientInitialized }
  )
}

export const useQuestionPoolList = (currentUser, locale) => {
  return useQuery(
    ['pool-data', currentUser.id],
    () => getPool(currentUser, locale),
    {
      enabled: currentUser.clientInitialized,
    }
  )
}
export const useQuestionPools = (currentUser: AuthUser, locale: string) =>
  useQuery(
    ['questionPool-list', currentUser.id],
    () => getPool(currentUser, locale),
    {
      staleTime: 60 * 60 * 1000, // 1hr
      cacheTime: 60 * 60 * 1000,
    }
  )

export const useQuestions = (
  currentUser: AuthUser,
  questionPoolId: string,
  locale: string,
  enabled: boolean = true
) =>
  useQuery(
    ['questions-questionpool-list', questionPoolId],
    () => getQuestions(locale, currentUser, questionPoolId),
    {
      enabled: currentUser.clientInitialized && enabled && !!questionPoolId,
    }
  )

export const useQuestionsList = (
  currentUser: AuthUser,
  questionPoolId: string,
  locale: string,

  options: QuestionsFilterType,
  enabled: boolean = true
) => {
  return useQuery(
    [
      'questions-question-pool-list',
      questionPoolId,
      JSON.stringify(Object.values(options)),
    ],
    () => getQuestionsList(locale, currentUser, questionPoolId, options),
    {
      enabled: currentUser.clientInitialized && enabled && !!questionPoolId,
      keepPreviousData: true,
    }
  )
}

export const useQuestionSetQuestionList = (
  currentUser: AuthUser,
  questionSetId: string,
  locale: string
) => {
  return useQuery(
    ['added-questionSet-Questions', questionSetId],
    () => getQuestionSetQuestionList(locale, currentUser, questionSetId),
    {
      enabled: currentUser.clientInitialized && !!questionSetId,
    }
  )
}

export const useMCQTags = (
  currentUser: AuthUser,
  locale: string,
  QuestionSetIdentity: string,
  QuestionPoolIdentity: string
) => {
  return useQuery(
    ['MCQ_TAGS', currentUser.id, QuestionPoolIdentity],
    () =>
      getTags(currentUser, locale, QuestionSetIdentity, QuestionPoolIdentity),
    {
      enabled: currentUser.clientInitialized,
    }
  )
}

export const useMCQParticipants = (
  currentUser: AuthUser,
  locale,
  slug: string,
  query: any
) => {
  return useQuery(
    [currentUser.id, locale, slug, JSON.stringify(Object.values(query))],
    () => getQuestionSetParticipants(locale, currentUser, slug, query)
  )
}

export const useQuestionSetResultQuery = (
  currentUser: AuthUser,
  locale,
  slug: string,
  query: any
) => {
  return useQuery(
    [
      `result of one questionSet`,
      slug,
      currentUser.id,
      JSON.stringify(Object.values(query)),
    ],
    () => getAssessmentResults(currentUser, slug, locale, query),
    {
      enabled: currentUser.clientInitialized,
      keepPreviousData: true,
    }
  )
}

export default useCategories
