import googleTagManager from '@analytics/google-tag-manager'
import Analytics from 'analytics'
import umamiPlugin from './umamiPlugin'

const plugins =
  process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT === 'production'
    ? [
        // firebaseGA4Plugin({}),
        // facebookPixelPlugin({
        //   pixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
        // }),
        googleTagManager({
          containerId: 'GTM-WBQ2LBH',
        }),
        umamiPlugin(),
      ]
    : []

const analytics = Analytics({
  app: 'vurilo-web-app',
  debug: process.env.NODE_ENV === 'development',
  plugins: plugins,
})

/* export the instance for usage in your app */
export default analytics
