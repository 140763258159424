import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'
import qs from 'query-string'

export const addPool = (
  currentUser: AuthUser,
  locale: string,
  name: string,
  description: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool`

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        description,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}
export const getPool = (currentUser: AuthUser, locale: string) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}
export const updatePool = (
  currentUser: AuthUser,
  locale: string,
  questionPoolId: string,
  name: string,
  description: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${questionPoolId}`

    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        description,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}
export const deletePool = (
  currentUser: AuthUser,
  locale: string,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${id}`

    return fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const getSinglePool = (
  currentUser: AuthUser,
  locale: string,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${id}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const addPoolInvigilator = (
  currentUser: AuthUser,
  locale: string,
  questionPoolIdentity: string,
  emailOrPhoneNumber: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPoolTeacher`

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        questionPoolIdentity,
        emailOrPhoneNumber,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const getPoolInvigilator = (
  currentUser: AuthUser,
  locale: string,
  questionPoolIdentity: string,
  search?: string,
  page?: number
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(search && { search }),
      ...(page && page > 0 && { page }),
      ...(questionPoolIdentity && { questionPoolIdentity }),
    })
    let url = `${Config.API_BASE_URL}/api/QuestionPoolTeacher?${queryString}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const deletePoolInvigilator = (
  currentUser: AuthUser,
  locale: string,
  poolIdentity: string,
  userId: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPoolTeacher/${poolIdentity}/remove/${userId}`

    return fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r
    })
  })
}

export const addPoolQuestions = (
  currentUser: AuthUser,
  locale: string,
  id: string,
  data
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${id}/addQuestion`

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const updatePoolInvigilatorRole = (
  currentUser: AuthUser,
  locale: string,
  poolIdentity: string,
  userId: string,
  role: number
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPoolTeacher/${poolIdentity}/assignrole/${userId}`

    return fetch(url, {
      method: 'PATCH',
      body: JSON.stringify({
        role,
        poolIdentity,
        userId,
      }),

      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    })
  })
}

export const deletePoolQuestion = (
  currentUser: AuthUser,
  locale: string,
  identity: string,
  questionId: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${identity}/question/${questionId}/delete`

    return fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const updatePoolQuestion = (
  currentUser: AuthUser,
  locale: string,
  identity: string,
  questionId: string,
  data
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${identity}/question/${questionId}`

    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const getPoolQuestion = (
  currentUser: AuthUser,
  locale: string,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/Question/${id}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}

export const addBulkPoolQuestions = (
  currentUser: AuthUser,
  locale: string,
  identity: string,
  data: FormData
) => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/QuestionPool/${identity}/BulkUpload`

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: 'Bearer ' + token,
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r
    })
  })
}

export const getTags = (
  currentUser: AuthUser,
  locale: string,
  questionSetIdentity?: string,
  questionPoolIdentity?: string,
  search?: string
) => {
  return currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify({
      ...(search && { search }),
      ...{ size: 1000 },
      ...(questionSetIdentity && { QuestionSetIdentity: questionSetIdentity }),
      ...(questionPoolIdentity && { questionPoolIdentity }),
    })
    let url = `${Config.API_BASE_URL}/api/Tag?${queryString}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r.json()
      }
      return await r.json()
    })
  })
}
