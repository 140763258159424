import { useUI } from '@components/ui'
import analytics from '@lib/analytics'
import { createAccountToBackEnd, redirect } from '@lib/utils/authHelper'
import toaster from '@lib/utils/notify'
import firebase from 'firebase/app'
import { useAuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import { useState } from 'react'

export const WithLogin = (ChildComponent) => {
  const WithLoginHOC = (props) => {
    const currentUser = useAuthUser()
    const reload = !currentUser.id
    var googleProvider = new firebase.auth.GoogleAuthProvider()
    var facebookProvider = new firebase.auth.FacebookAuthProvider()
    facebookProvider.addScope('email')
    facebookProvider.addScope('public_profile')
    var appleProvider = new firebase.auth.OAuthProvider('apple.com')

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState('')
    const { closeModal } = useUI()

    const router = useRouter()
    const locale = router.locale

    const onSignInWithGoogle = async () => {
      try {
        setMessage('')
        firebase.auth().languageCode = 'ne'
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const authData = await firebase.auth().signInWithPopup(googleProvider)
        await createAccountToBackEnd(locale)
        closeModal()
        analytics.track('login', {
          method: 'Google',
        })
        analytics.identify(firebase.auth().currentUser.uid)
        if (props.onSuccess) {
          props.onSuccess()
        } else {
          if (props.type === 'modal') {
            closeModal()
          } else {
            redirect(router)
          }
        }
        if (reload) {
          setTimeout(() => {
            window.location.reload()
          }, 600)
        }
      } catch (error) {
        setMessage(error.message)
      }
    }

    const onSignInWithApple = async () => {
      analytics.track('onClick_login', {
        location: 'Login Page',
        method: 'apple',
      })
      try {
        setMessage('')
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const authData = await firebase.auth().signInWithPopup(appleProvider)

        await createAccountToBackEnd(locale)
        closeModal()
        analytics.track('login', {
          method: 'Apple',
        })
        analytics.identify(firebase.auth().currentUser.uid)
        if (props.onSuccess) {
          props.onSuccess()
        } else {
          if (props.type === 'modal') {
            closeModal()
          } else {
            redirect(router)
          }
        }
        if (reload) {
          setTimeout(() => {
            window.location.reload()
          }, 600)
        }
      } catch (error) {
        setMessage(error.message)
      }
    }

    const onSignInWithFacebook = async () => {
      analytics.track('onClick_login', {
        location: 'Login Page',
        method: 'facebook',
      })
      try {
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const authData = await firebase.auth().signInWithPopup(facebookProvider)

        await createAccountToBackEnd(locale)
        closeModal()
        analytics.track('login', {
          method: 'Facebook',
        })
        analytics.identify(firebase.auth().currentUser.uid)

        if (props.onSuccess) {
          props.onSuccess()
        } else {
          if (props.type === 'modal') {
            closeModal()
          }
        }
        if (reload) {
          setTimeout(() => {
            window.location.reload()
          }, 600)
        }
      } catch (error) {
        setMessage(error.message)
      }
    }

    // for mobile number login
    const handleLogin = async (data, setFieldValue) => {
      analytics.track('onClick_login', {
        location: 'Login Page',
        method: 'google',
      })
      try {
        setMessage('')
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        await firebase
          .auth()
          .signInWithEmailAndPassword(data.email, data.password)
        analytics.track('login', {
          method: 'Password',
        })

        analytics.identify(firebase.auth().currentUser.uid)
        await createAccountToBackEnd(locale)
        if (props.onSuccess) {
          props.onSuccess()
        } else {
          if (props.type === 'modal') {
            closeModal()
          } else {
            redirect(router)
          }
        }
        if (reload) {
          setTimeout(() => {
            window.location.reload()
          }, 600)
        }
      } catch (error) {
        setFieldValue('password', '')
        setMessage(error.message)
      }
    }
    const [conf, setConf] = useState<firebase.auth.ConfirmationResult>()
    const [showOtp, setShowOtp] = useState(false)

    const loginWithOtp = (otp, reload = true) => {
      if (!otp) {
        return
      }
      setLoading(true)

      conf
        .confirm(otp)
        .then(async (result) => {
          await createAccountToBackEnd(locale)
          closeModal()
          if (props.onSuccess) {
            props.onSuccess()
          } else {
            if (props.type === 'modal') {
            } else {
              redirect(router)
            }
            toaster.success('Successfully logged in')
          }
          setLoading(false)
          if (reload) {
            setTimeout(() => {
              window.location.reload()
            }, 600)
          }
        })
        .catch((err) => {
          toaster.failure(err.message || err.error?.message)
          setLoading(false)
        })
    }

    const onPhoneNumberSubmit = async (phoneNo) => {
      setLoading(true)
      let recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signIn', {
        size: 'invisible',
      })
      firebase
        .auth()
        .signInWithPhoneNumber('+' + phoneNo, recaptchaVerifier)
        .then((confirmationResult) => {
          setConf(confirmationResult)
          setShowOtp(true)
          document.getElementById('otp')?.focus()
        })
        .catch((error) => {
          toaster.failure(error.message)
          recaptchaVerifier.clear()
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }

    const linkWithPhone = (phoneNumber: string) => {
      setLoading(true)
      let recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signIn', {
        size: 'invisible',
      })
      currentUser.firebaseUser
        .linkWithPhoneNumber('+' + phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          setConf(confirmationResult)
          setShowOtp(true)
          setLoading(false)

          document.getElementById('otp')?.focus()
        })

        .catch((error) => {
          toaster.failure(error.message)
          recaptchaVerifier.clear()
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }

    return (
      <div id="loginHOC">
        <ChildComponent
          {...props}
          handelLogin={handleLogin}
          message={message}
          loading={loading}
          onSignInWithGoogle={onSignInWithGoogle}
          onSignInWithApple={onSignInWithApple}
          onSignInWithFacebook={onSignInWithFacebook}
          onPhoneNumberSubmit={onPhoneNumberSubmit}
          onSignInWithOtp={loginWithOtp}
          showOtp={showOtp}
          linkWithPhoneNumber={linkWithPhone}
        />
      </div>
    )
  }
  WithLoginHOC.displayName = 'WithLoginHOC'
  return WithLoginHOC
}
