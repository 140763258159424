import React, { FC, InputHTMLAttributes } from 'react'
import './Checkbox.module.css'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onChangeHandler?: Function
  label: any
  isCorrect?: boolean
  inputClass?: any
}

const Checkbox: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    label,
    className,
    children,
    checked,
    disabled,
    onChangeHandler,
    isCorrect,
    inputClass,
    ...rest
  } = props
  return (
    <label
      className={`flex justify-start items-center cursor-pointer ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
    >
      <div
        className={`${
          isCorrect ? 'bg-primary' : 'bg-white'
        } border-2 rounded border-current w-4 h-4 flex shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 ${className}`}
      >
        <input
          type="checkbox"
          className={`opacity-0 my-checkbox absolute bg-orange-400
           `}
          {...rest}
          checked={checked}
          disabled={disabled}
        />
        <svg
          className={`fill-current ${
            checked ? 'visible' : 'hidden'
          } w-4 h-4 text-brand-dark pointer-events-none`}
          viewBox="0 0 20 20"
        >
          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
        </svg>
      </div>
      <div className="select-none">{label}</div>
    </label>
  )
}

export default Checkbox
