import React, { FC } from 'react'

interface Props {
  className?: string
}
const WhatsApp: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>WhatsApp</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43359 21.6515L3.78059 16.734C2.94959 15.295 2.51259 13.662 2.51309 11.9895C2.51559 6.757 6.77359 2.5 12.0066 2.5C14.5461 2.501 16.9291 3.4895 18.7216 5.283C20.5136 7.077 21.5006 9.461 21.4996 11.997C21.4976 17.2295 17.2386 21.487 12.0066 21.487C12.0061 21.487 12.0066 21.487 12.0066 21.487H12.0026C10.4141 21.4865 8.85259 21.088 7.46609 20.3315L2.43359 21.6515Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43338 21.9015C2.36738 21.9015 2.30338 21.8755 2.25588 21.8275C2.19338 21.764 2.16888 21.6715 2.19238 21.586L3.51188 16.768C2.69388 15.315 2.26238 13.665 2.26338 11.99C2.26538 6.619 6.63587 2.25 12.0064 2.25C14.6114 2.251 17.0589 3.2655 18.8984 5.1065C20.7379 6.948 21.7504 9.395 21.7494 11.997C21.7474 17.3675 17.3764 21.737 12.0064 21.737C10.4119 21.7365 8.83437 21.343 7.43437 20.5985L2.49688 21.893C2.47588 21.899 2.45488 21.9015 2.43338 21.9015Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0068 2.5C14.5463 2.501 16.9293 3.4895 18.7218 5.283C20.5138 7.077 21.5008 9.461 21.4998 11.997C21.4978 17.2295 17.2388 21.487 12.0068 21.487H12.0028C10.4143 21.4865 8.8528 21.088 7.4663 20.3315L2.4338 21.6515L3.7808 16.734C2.9498 15.295 2.5128 13.662 2.5133 11.9895C2.5158 6.757 6.7738 2.5 12.0068 2.5ZM12.0068 2C6.4988 2 2.0158 6.481 2.0133 11.9895C2.0128 13.673 2.4378 15.332 3.2438 16.8005L1.9513 21.52C1.9043 21.6925 1.9523 21.8765 2.0783 22.0035C2.1733 22.0995 2.3018 22.152 2.4338 22.152C2.4763 22.152 2.5188 22.1465 2.5608 22.1355L7.4043 20.8655C8.8183 21.5995 10.4033 21.987 12.0028 21.9875C17.5148 21.9875 21.9978 17.506 22.0003 11.9975C22.0013 9.328 20.9628 6.818 19.0763 4.93C17.1888 3.0415 14.6783 2.001 12.0068 2Z"
        fill="#CFD8DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5872 6.41603C16.0972 4.92503 14.1167 4.10353 12.0087 4.10303C7.65673 4.10303 4.11723 7.64103 4.11523 11.99C4.11473 13.4805 4.53173 14.9315 5.32174 16.188L5.50973 16.4865L4.71223 19.397L7.69874 18.614L7.98724 18.785C9.19824 19.504 10.5872 19.884 12.0032 19.8845H12.0062C16.3552 19.8845 19.8947 16.346 19.8962 11.9965C19.8967 9.88903 19.0772 7.90703 17.5872 6.41603Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63314 8.02244C9.45564 7.62744 9.26864 7.61944 9.09914 7.61244C8.96064 7.60644 8.80264 7.60694 8.64464 7.60694C8.48664 7.60694 8.22964 7.66644 8.01214 7.90394C7.79464 8.14144 7.18164 8.71494 7.18164 9.88194C7.18164 11.0489 8.03164 12.1769 8.15014 12.3349C8.26864 12.4929 9.79114 14.9644 12.2021 15.9154C14.2056 16.7054 14.6136 16.5484 15.0486 16.5089C15.4836 16.4694 16.4521 15.9354 16.6496 15.3814C16.8471 14.8274 16.8471 14.3529 16.7881 14.2539C16.7286 14.1549 16.5706 14.0959 16.3336 13.9769C16.0966 13.8579 14.9301 13.2844 14.7126 13.2054C14.4951 13.1264 14.3371 13.0869 14.1786 13.3244C14.0206 13.5614 13.5661 14.0959 13.4276 14.2539C13.2891 14.4124 13.1506 14.4324 12.9136 14.3134C12.6766 14.1944 11.9126 13.9444 11.0061 13.1364C10.3011 12.5079 9.82514 11.7314 9.68664 11.4939C9.54814 11.2569 9.67164 11.1284 9.79064 11.0099C9.89714 10.9034 10.0276 10.7329 10.1466 10.5944C10.2651 10.4559 10.3046 10.3569 10.3836 10.1989C10.4626 10.0404 10.4231 9.90194 10.3636 9.78344C10.3051 9.66444 9.84414 8.49144 9.63314 8.02244Z"
        fill="#2E2E2E"
      />
    </svg>
  )
}

export default WhatsApp
