import { BottomFooter } from '@components/common'
import { Container } from '@components/ui'
import cn from 'classnames'
import React, { FC } from 'react'
import s from './AuthLayout.module.css'

interface Props {}

const AuthLayout: FC<
  React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({ children }) => {
  return (
    <>
      <div className={cn(s.root)}>
        <main className="fit flex items-center mx-auto w-80">{children}</main>
        <Container>
          <BottomFooter></BottomFooter>
        </Container>
      </div>
    </>
  )
}

export default AuthLayout
