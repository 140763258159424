import { Star } from '@components/icons'
import { floor, map, times } from 'lodash'
import * as React from 'react'

const FiveStars: React.FC<
  React.PropsWithChildren<{
    rating: any
  }>
> = ({ rating }) => {
  const remainder = parseFloat((rating % 1).toFixed(1))
  const roundedRemainder = Math.ceil(remainder)
  const showHalfStar = roundedRemainder === 1
  const emptyStarCount = 5 - roundedRemainder - floor(rating)
  return (
    <div className="flex items-center leading-tight">
      {map(times(rating), (index) => (
        <div key={`filled-${index}`}>
          <Star outline={false} half={false} filled={true} />
        </div>
      ))}
      {showHalfStar && (
        <div key={`half`}>
          <Star outline={false} half={true} filled={false} />
        </div>
      )}
      {map(times(emptyStarCount), (index) => (
        <div key={`empty-${index}`}>
          <Star outline={true} half={false} filled={false} />
        </div>
      ))}
    </div>
  )
}

export default FiveStars
