import 'katex/dist/katex.min.css'
import * as React from 'react'
import { BlockMath, InlineMath } from 'react-katex'
import ReactMarkdown from 'react-markdown'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import rehypeKatex from 'rehype-katex'
import emoji from 'remark-emoji'
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'
import SimpleBar from 'simplebar-react'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import PrismTheme from './PrismTheme'

const components = {
  code(props) {
    const { inline, className, children } = props
    const match = /language-(\w+)/.exec(className || '')
    return !inline && match ? (
      <div className="group relative sm:mx-0 sm:rounded-md rounded-none overflow-hidden">
        <pre
          className="sm:mx-0 sm:px-0 text-sm leading-7 rounded-none m-0 p-0"
          style={{ margin: '0px !important', padding: '0 !important' }}
        >
          <CopyToClipboard
            className="group-hover:block hidden"
            code={children.toString()}
          />
          {/* @ts-ignore */}
          <SimpleBar autoHide={false}>
            <SyntaxHighlighter
              // @ts-ignore
              style={PrismTheme}
              language={match[1]}
              PreTag="div"
              children={String(children)}
            ></SyntaxHighlighter>
          </SimpleBar>
        </pre>
      </div>
    ) : (
      <code className={className} {...props} />
    )
  },
  a(props) {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className="text-primary hover:cursor-pointer hover:underline"
      >
        {props.children}
      </a>
    )
  },
  ol(props) {
    return <ol className="list-decimal">{props.children}</ol>
  },
  ul(props) {
    return <ul className="list-disc">{props.children}</ul>
  },

  math: ({ value }) => <BlockMath>{value}</BlockMath>,
  inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
}

const Markdown: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
}) => {
  return (
    <ReactMarkdown
      remarkPlugins={[gfm, emoji, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={components}
      className="prose-lg text-justify break-words"
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
