import { Link } from '@components/ui'
import { LinkItem } from '@lib/types'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  items: LinkItem[]
}

const Breadcrumb: FC<React.PropsWithChildren<Props>> = ({ items }) => {
  const { t } = useTranslation()
  return (
    <div className="text-sm  breadcrumbs">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {index + 1 === items.length ? (
              t(item.name)
            ) : (
              <Link href={item.url}>
                <span className="truncate max-w-xs cursor-pointer">
                  {t(item.name)}
                </span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Breadcrumb
