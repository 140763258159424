import React, { FC, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
  title?: string
  height?: number
  width?: number
  viewBoxMinWidth?: number
  viewBoxMinHeight?: number
  viewBoxWidth: number
  viewBoxHeight: number
}

const SvgIcon: FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  title,
  height,
  width,
  viewBoxMinWidth = 0,
  viewBoxMinHeight = 0,
  viewBoxWidth = width,
  viewBoxHeight = height,
}) => (
  <svg
    className={className}
    height={height}
    width={width}
    viewBox={`${viewBoxMinWidth} ${viewBoxMinHeight} ${viewBoxWidth} ${viewBoxHeight}`}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={`${title}-title`}
    style={{ verticalAlign: 'middle' }}
  >
    <title id={`${title}-title`}>{title}</title>
    {children}
  </svg>
)

export default SvgIcon
