import Link, { LinkProps as NextLinkProps } from 'next/link'

const AppLink: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<NextLinkProps>>
> = ({ children, ...props }) => {
  return (
    <Link {...props} legacyBehavior>
      {children}
    </Link>
  )
}

export default AppLink
