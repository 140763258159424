import React, { FC } from 'react'

interface Props {}

const TilesContainer: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  return (
    <div className="flex flex-wrap justify-center relative">{children}</div>
  )
}

export default TilesContainer
