import getUserFullName from './getUserFullName'

const getCourseOgImageUrl = (
  author,
  title: string,
  thumbnailUrl: string,
  theme = 'light'
) => {
  return thumbnailUrl && thumbnailUrl !== ''
    ? thumbnailUrl
    : `https://imager.apps.vurilo.com?title=${encodeURIComponent(
        title
      )}&author=${encodeURIComponent(
        getUserFullName(author)
      )}&image=${encodeURIComponent(author.imageUrl)}&theme=${theme}`
}

export default getCourseOgImageUrl
