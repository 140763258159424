const getUserFullName = (user) => {
  if (!user) {
    return ''
  }
  if (user.firstName) {
    const name = `${user.firstName}` + ' ' + user.lastName
    return name
  }
  return user.lastName || ''
}

export default getUserFullName
