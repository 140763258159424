export const Messages = {
  FORGOT_PASSWORD_EMAIL_SENT_MESSAGE:
    'The email with link to reset password is sent. Please check your email.',
  EMAIL_VERIFIED: 'Congratulation! your email is verified.',
  EMAIL_RECOVERED: 'Congratulation! your email is recovered now to',
}

export const ENUMS = {
  COURSE_PRICE_TYPE: {
    FREE: 'Free',
    PREMIUM: 'Premium',
  },
  COURSE_LANGUAGES: {
    ENGLISH: '1',
    NEPALI: '2',
  },
  COURSE_LEVEL: {
    BEGINNER: '1',
    INTERMEDIATE: '2',
    ADVANCED: '3',
    ALL: '4',
  },
  TEAM_INDUSTRY: {
    1: 'School',
    2: 'Government Organization',
    3: 'Finance & Banking',
  },
  LIVE_SCHEDULE: {
    ALL: 'All',
    DAILY: '4',
    WEEKLY: '5',
    MONTHLY: '6',
  },

  ASSIGNMENT_MARKING_CRITERIA: {
    1: 'Marks',
    2: 'Grading',
  },
  PAYMENT_GATEWAY: {
    esewa: 6,
    khalti: 2,
  },

  EVENT_COURSE_TYPE: {
    EXISTING: '2',
    NEW: '1',
  },

  DAYS_OF_WEEK: {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  },

  MONTHLY_WEEK: {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    FIFTH: 5,
  },

  EVENT_PRICE: {
    FREE: 0,
  },
  MCQ_TYPE: {
    TEXT: 0,
    IMAGE: 1,
  },
  QUESTION_POOL_TYPE: {
    Checkbox: 0,
    Radio: 1,
  },
}

export enum SCOPE {
  PUBLIC = 1,
  TEAM = 2,
}

export enum LIVE_SESSION_EVENT_TYPE {
  RECURRING = 1,
  SINGLE = 2,
}

export enum WORKFLOW_STATUS {
  Draft = 0,
  Team = 1,
  Review = 2,
  Publish = 3,
  Reject = 4,
}

export enum EnrollmentMemberStatusEnum {
  Enrolled = 1,
  Completed = 2,
  Unenrolled = 3,
}

export enum LiveSessionEnrollmentStatus {
  HOST = 1,
  ENROLLED = 2,
  PUBLIC = 3,
  MODERATOR = 4,
}

export enum PaymentLessonType {
  Course = 1,
  LiveSession = 2,
  MCQs = 3,
}

export enum MCQQuestionSetTypeStatus {
  AUTHOR = 1,
  ENROLLED = 2,
  NOT_ENROLLED = 3,
  INVIGILATOR = 4,
}

export enum MCQQuestionSetStatus {
  DRAFT = 0,
  PUBLISHED = 1,
  COMPLETED = 2,
  ARCHIVED = 3,
  REVIEW = 4,
  REJECTED = 5,
}

export enum LIVE_SESSION_HOST_TYPE {
  ZOOM = 1,
  JITSI = 2,
}
export enum LIVE_SESSION_ENROLLMENT {
  None = 0,
  Esewa = 1,
  Khalti = 2,
  Cash = 3,
  NIBLBank = 4,
  MBLBank = 5,
  EsewaWeb = 6,
}

const teacherHandBookURL =
  'https://drive.google.com/file/d/1ZYH8gL6IgajH9-1nvqPj8dkxcq5fX1vn/view?usp=sharing'

export const Config = {
  API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
  VIDEO_SERVICE_URL: process.env.NEXT_PUBLIC_VIDEO_SERVICE_URL,
  TeacherHandBookURL: teacherHandBookURL,
  searchResultCount: 22,
  searchUrlRoot: `/search`,
}

export const AssignmentStatus = {
  Pending: {
    text: 'Pending',
    color: 'currentColor',
  },
  Approved: {
    text: 'Approved',
    color: 'success',
  },
  Submitted: {
    text: 'Submitted',
    color: 'success',
  },
  NotSubmitted: {
    text: 'Not submitted',
    color: 'currentColor',
  },
  LateSubmitted: {
    text: 'Late Submitted',
    color: 'error',
  },
}

export const frequency = {
  daily: '4',
  weekly: '5',
  monthly: '6',
}
export const providerMap = {
  'google.com': 'Google',
  'facebook.com': 'Facebook',
  'apple.com': 'Apple',
  password: 'Email',
  phone: 'phone Number',
}

export enum LoginView {
  Email_Password,
  PhoneNumber,
}

export enum CustomFieldType {
  Text = 1,
  File = 2,
  Radio = 3,
  CheckBox = 4,
}
