import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'

/**
 * Uploads the file
 * @param currentUser the current logged in user
 * @param type the media/file type Avatar = 1, Thumbnail = 2,  Video = 3
 * @param file the file
 * @returns response of API which contains the cloudfront URL.
 */
export const uploadFile = (
  locale: string,
  currentUser: AuthUser,
  type: 1 | 2 | 3,
  file: any,
  uploadToImageKit: boolean = false
): Promise<{ url: string }> => {
  return new Promise((resolve, reject) => {
    // if (uploadToImageKit) {
    //   imagekit.upload(
    //     {
    //       file: file,
    //       fileName: file.name,
    //       folder: type === 1 ? 'avatar' : 'thumbnail',
    //     },
    //     (err: any, res: any) => {
    //       if (err) {
    //         reject(err.message)
    //       }
    //       resolve({
    //         url: res?.url as string,
    //       })
    //     }
    //   )
    // } else {
    currentUser.getIdToken().then((token) => {
      const formData = new FormData()
      formData.append('type', type.toString())
      formData.append('file', file)
      fetch(`${Config.API_BASE_URL}/api/file`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      })
        .then(async (r) => {
          if (!r.ok) {
            reject('Error occurred while uploading')
          }
          const url = await r.json()
          resolve({
            url: url as string,
          })
        })
        .catch((err) => {})
    })
    // }
  })
}

export const lessonFileUpload = (
  locale: string,
  currentUser: AuthUser,
  file: any,
  lessonId: string
): Promise<{
  lessonId: string
  name: string
  memeType: string
  fileSize: number
  isFree: boolean
}> => {
  return new Promise((resolve, reject) => {
    currentUser.getIdToken().then((token) => {
      const formData = new FormData()
      formData.append('file', file, file.name)
      fetch(`${Config.API_BASE_URL}/api/Lesson/${lessonId}/attachment`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Accept-Language': locale,
        },
        body: formData,
      }).then(async (r) => {
        if (!r.ok) {
          reject('Error occurred while uploading')
        }
        const url = await r.json()
        resolve({
          lessonId: url.lessonId as string,
          name: url.name as string,
          memeType: url.memeType as string,
          fileSize: url.fileSize as number,
          isFree: url.isFree as boolean,
        })
      })
    })
  })
}

// ---------------------Upload Form File ----------------------------

export const uploadFormFile = (
  currentUser: AuthUser,
  file: any,
  fileName: string
): Promise<{ url: string }> => {
  return new Promise((resolve, reject) => {
    currentUser.getIdToken().then((token) => {
      const formData = new FormData()

      formData.append('File', file, fileName)
      fetch(`${Config.VIDEO_SERVICE_URL}/api/Media`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      })
        .then(async (r) => {
          if (!r.ok) {
            reject('Error occurred while uploading')
          }
          const url = await r.json()
          resolve({
            url: url as string,
          })
        })
        .catch((err) => {})
    })
    // }
  })
}
