import ApproveEmailBanner from '@components/auth/ApproveEmailBanner'
import LoginView from '@components/auth/LoginView'
import UpdateUserName from '@components/auth/UpdateUserName'
import { Footer, Navbar } from '@components/common'
import { Container, Modal } from '@components/ui'
import { useUI } from '@components/ui/context'
import LoadingDots from '@components/ui/LoadingDots'
import AuthProvider from '@lib/utils/authHelper/authContext'
import cn from 'classnames'
import firebase from 'firebase'
import { useAuthUser, withAuthUser } from 'next-firebase-auth'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import s from './Layout.module.css'

const Loading = () => (
  <div className="w-80 h-80 flex items-center text-center justify-center p-3 text-base-content ">
    <LoadingDots />
  </div>
)

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  loading: () => <Loading />,
  ssr: false,
})

const ForgotPassword = dynamic(
  () => import('@components/auth/ForgotPassword'),
  { loading: () => <Loading />, ssr: false }
)

interface Props {
  mainClassName?: string
  modals?: React.ReactNode
}
const ADD_USER_NAME_MODEL = 'ADD_USER_NAME_MODEL'

const Layout: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  mainClassName = '',
  modals,
}) => {
  const [user, setUser] = useState({
    isUserLoggedIn: false,
    isEmailVerified: false,
    isPhoneNumber: false,
    isVerifiedUser: false,
  })

  const { displayModal, closeModal, modalView, setModalView, openModal } =
    useUI()
  const currentUser = useAuthUser()
  const [emailVerified, setEmailVerified] = useState(true)
  const [showBanner, setShowBanner] = useState(false)
  const [contactBanner, setContactBanner] = useState(false)
  const route = useRouter()

  useEffect(() => {
    if (currentUser.clientInitialized) {
      firebase?.auth().onAuthStateChanged((user) => {
        if (user) {
          const displayName = user.displayName?.trim()
          if (!displayName && !displayModal) {
            setModalView(ADD_USER_NAME_MODEL)
            openModal()
          }
          if (displayName && modalView === 'ADD_USER_NAME_MODEL') {
            setModalView('')
            closeModal()
          }
        }
      })
    }
  }, [currentUser, modalView, displayModal])

  useEffect(() => {
    currentUser.clientInitialized && setShowBanner(true)
    if (currentUser.clientInitialized && currentUser.id) {
      setUser({
        ...user,
        isUserLoggedIn: true,
        isEmailVerified: currentUser.emailVerified,
        isPhoneNumber: !!currentUser.phoneNumber,
        isVerifiedUser: currentUser.emailVerified || !!currentUser.phoneNumber,
      })
      if (!currentUser.emailVerified && !currentUser.phoneNumber) {
        setEmailVerified(false)
      }
    }
  }, [currentUser.clientInitialized, currentUser.emailVerified, currentUser])

  // toggle messenger icon
  useEffect(() => {
    var el = document.getElementById('fb-root')

    if (currentUser.id) {
      if (el) el.style.display = 'none'
    } else {
      if (el) el.style.display = 'block'
    }
  }, [currentUser.id, currentUser, currentUser.clientInitialized])

  return (
    <AuthProvider.Provider value={user}>
      <div className={cn(s.root)}>
        {showBanner && (
          <div className="h-auto ">
            {/* {!currentUser.id && <ContactBanner></ContactBanner>} */}
            <div className="w-full right-0 top-0 sm-text">
              {!emailVerified && currentUser.id && <ApproveEmailBanner />}
            </div>
          </div>
        )}
        <Navbar />
        <main className={`flex-auto bg-fixed fit ${mainClassName}`}>
          <Container clean={false}>{children}</Container>
        </main>
        <Footer />
        {(modalView === 'LOGIN_VIEW' ||
          modalView === 'SIGNUP_VIEW' ||
          modalView === 'FORGOT_VIEW') && (
          <Modal
            open={displayModal}
            onClose={closeModal}
            isOverFlowModal={true}
            isTextInput={true}
          >
            <div>
              {/* @ts-ignore */}
              {modalView === 'LOGIN_VIEW' && <LoginView type="modal" />}
              {/* @ts-ignore */}
              {modalView === 'SIGNUP_VIEW' && <SignUpView type="modal" />}
              {/* @ts-ignore */}
              {modalView === 'FORGOT_VIEW' && <ForgotPassword type="modal" />}

              {modals}
            </div>
          </Modal>
        )}
        {modalView === ADD_USER_NAME_MODEL && (
          <Modal onClose={closeModal} open={displayModal} showClose={false}>
            <UpdateUserName />
          </Modal>
        )}
      </div>
    </AuthProvider.Provider>
  )
}

export default withAuthUser({})(Layout)
