import { createAssignments } from '@lib/services'
import { default as FilePondPluginFileValidateSize } from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { AuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { useTranslation } from 'react-i18next'
// Register the plugin
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

type Props = {
  currentUser: AuthUser
  courseId: string

  onUploadSuccess: Function
}

const AssignmentUpload: FC<React.PropsWithChildren<Props>> = ({
  currentUser,
  courseId,

  onUploadSuccess,
}) => {
  const [files, setFiles] = useState([])
  const { t } = useTranslation()
  const router = useRouter()
  const { locale } = router

  const handleToRemoveImage = (errRes, file) => {
    if (errRes == null) {
      setFiles([])
    }
  }
  const clearFile = () => {
    setFiles([])
  }

  return (
    <div>
      {/* @ts-ignore */}

      <FilePond
        // @ts-ignore
        credits={false}
        allowFileTypeValidation={true}
        allowMultiple={false}
        files={files}
        instantUpload={true}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file))
        }}
        acceptedFileTypes={[
          'image/png',
          'image/jpeg',
          'image/gif',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/x-freearc',
          'application/x-bzip',
          'application/x-bzip2',
          'application/gzip',
          'application/vnd.oasis.opendocument.presentation',
          'application/vnd.ms-powerpoint',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/zip',
          'application/x-7z-compressed',
          'application/x-rar-compressed',
          'application/octet-stream',
          'application/octet-stream',
          'application/x-zip-compressed',
          'multipart/x-zip',
        ]}
        imagePreviewHeight={50}
        onremovefile={(errRes, file) => handleToRemoveImage(errRes, file)}
        // @ts-ignore
        allowImageResize={true}
        labelIdle={`${t(
          'drag_drop_file_msg'
        )} <span class="filepond--label-action"> ${t('browse')} </span>`}
        server={{
          remove: null,
          revert: null,
          //processing a file
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            try {
              const res = await createAssignments(locale, currentUser, file)
              if (onUploadSuccess) {
                onUploadSuccess(res, clearFile)
              }
            } catch (e) {
              error(t('error_upload_msg'))
            }
            return {
              abort: () => {
                abort()
              },
            }
          },
          load: async (source, load, error, progress, abort, headers) => {
            await fetch(source)
              .then(async (r) => {
                load(await r.blob())
              })
              .catch((r) => error(r))
            return {
              abort: () => {
                abort()
              },
            }
          },
        }}
      ></FilePond>
    </div>
  )
}

export default AssignmentUpload
