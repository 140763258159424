import {
  Button,
  ErrorMessageBox,
  FormErrorMessageBox,
  Input,
  useUI,
} from '@components/ui'
import { getProfile, updateProfile } from '@lib/services'
import getUserFullName from '@lib/utils/getUserFullName'
import firebase from 'firebase/app'
import { Field, Form, Formik } from 'formik'
import { useAuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import * as Yup from 'yup'

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()

    .max(50, 'first_name_less_msg')
    .required('firstname_required')
    .trim()
    .label('First name')
    .matches(/[A-Za-z]/, 'valid_first_name_msg'),

  lastName: Yup.string()
    .max(50, 'last_name_less_msg')
    .required('lastname_required')
    .trim()
    .label('Last name')
    .matches(/[A-Za-z]/g, 'valid_last_name_msg'),
})

const UpdateUserName = () => {
  const currentUser = useAuthUser()
  const myProfileQuery = useQuery(
    ['my-profile', currentUser.id],
    () => getProfile(currentUser, locale),
    { enabled: currentUser.clientInitialized }
  )

  const [message, setMessage] = useState('')
  const router = useRouter()
  const locale = router.locale
  const { t } = useTranslation()
  const { setModalView, closeModal } = useUI()

  const handelUpdateName = async (data) => {
    data.firstName = myProfileQuery.data?.firstName ?? data.firstName?.trim()
    data.lastName = myProfileQuery.data?.lastName ?? data.lastName?.trim()
    data.email = currentUser?.email
    data.mobileNumber = currentUser.phoneNumber
    data.profession = myProfileQuery.data?.profession
    data.bio = myProfileQuery.data?.bio
    data.webSiteUrl = myProfileQuery.data?.webSiteUrl
    data.imageUrl = myProfileQuery.data?.imageUrl
    data.facebookUrl = myProfileQuery.data?.facebookUrl
    data.twitterUrl = myProfileQuery.data?.twitterUrl
    data.instagramUrl = myProfileQuery.data?.instagramUrl
    data.youtubeUrl = myProfileQuery.data?.youtubeUrl
    data.linkedInUrl = myProfileQuery.data?.linkedInUrl
    data.gitHubUrl = myProfileQuery.data?.gitHubUrl

    try {
      const firebaseUser = firebase.auth().currentUser
      await updateProfile(currentUser, data, locale)
      await firebaseUser.updateProfile({
        displayName: `${getUserFullName(data)}`,
      })
      myProfileQuery.refetch()
      currentUser.firebaseUser.reload()
      closeModal()
    } catch (error) {
      const r = error.json()
      setMessage(r.message)
    }
  }
  return (
    <Formik
      onSubmit={handelUpdateName}
      validationSchema={editProfileSchema}
      initialValues={{ firstName: '', lastName: '' }}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col justify-between">
          <div className="flex flex-col space-y-4">
            <ErrorMessageBox
              message={message}
              hasError={!!message}
            ></ErrorMessageBox>

            <div className=" form-control grid grid-cols-1 pt-3">
              <div className="relative">
                <label htmlFor="firstName" className=" label">
                  <span className=" label-text">
                    {t('first_name')}
                    <span className="text-red-600 p-2">*</span>
                  </span>
                </label>
                <Field
                  name="firstName"
                  as={Input}
                  maxLength={50}
                  placeholder={t('first_name')}
                />
                <span className="absolute flex tracking-tight">
                  <FormErrorMessageBox name={'firstName'}></FormErrorMessageBox>
                </span>
              </div>
            </div>
            <div className=" form-control grid grid-cols-1  pt-3">
              <div className="relative">
                <label htmlFor="lastName" className=" label">
                  <span className=" label-text">
                    {t('last_name')}
                    <span className="text-red-600 p-2">*</span>
                  </span>
                </label>
                <Field
                  name="lastName"
                  as={Input}
                  maxLength={50}
                  placeholder={t('last_name')}
                />
                <span className="absolute flex tracking-tight">
                  <FormErrorMessageBox name={'lastName'}></FormErrorMessageBox>
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col pt-4">
              <Button variant="slim" type="submit" loading={isSubmitting}>
                {t('add')}
              </Button>
            </div>
          </div>
          {/* <FocusError /> */}
        </Form>
      )}
    </Formik>
  )
}

export default UpdateUserName
