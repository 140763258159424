import AppleIcon from '@components/icons/AppleIcon'
import GoogleIcon from '@components/icons/GoogleIcon'
import { WithLogin } from '@lib/hoc/LoginHoc'
import { useTranslation } from 'react-i18next'

const LoginWithSocialMedia = ({ onSignInWithGoogle, onSignInWithApple }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-auto justify-around items-center py-2  ">
        {/* <FacebookIcon
      onClick={onSignInWithFacebook}
      role="button"
      width="40"
      height="40"
      className="hover:scale-110 cursor-pointer"
    /> */}

        <GoogleIcon
          onClick={onSignInWithGoogle}
          role="button"
          width="40"
          height="40"
          style={{ padding: '1px' }}
          className="hover:scale-110 cursor-pointer "
        />

        <AppleIcon
          onClick={onSignInWithApple}
          role="button"
          width="40"
          height="40"
          className="hover:scale-110 cursor-pointer"
        />
      </div>
    </>
  )
}

export default WithLogin(LoginWithSocialMedia)
