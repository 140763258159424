const ToggleIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
      fill="currentColor"
      transform={props.open ? 'rotate(-90)' : 'rotate(0)'}
      {...props}
    >
      <path d="M7.412,24,6,22.588l9.881-9.881a1,1,0,0,0,0-1.414L6.017,1.431,7.431.017l9.862,9.862a3,3,0,0,1,0,4.242Z" />
    </svg>
  )
}

export default ToggleIcon
