import React from 'react'

type AuthProviderType = {
  isUserLoggedIn: boolean
  isEmailVerified: boolean
  isPhoneNumber: boolean
  isVerifiedUser: boolean
}

const AuthProvider = React.createContext<AuthProviderType>({
  isUserLoggedIn: false,
  isEmailVerified: false,
  isPhoneNumber: false,
  isVerifiedUser: false,
})

export default AuthProvider
